import { getCookie, getGeoLocation, isServer, isSistaminuten, promiseWrapper, trackMpEvent, url } from '@/helpers';
import { __ } from '@/locale';
import { autocompleteService } from '@/services';
import React, { createRef } from 'react';
import Autosuggest from 'react-autosuggest';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { CloseIconThin } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import { LOCATION_PLACEHOLDER } from '@/constants';
import { isMobile } from '@/helpers';
import withMobileView from '@/hoc/withMobileView';
import Modal from 'react-modal';
import FakeSearchInput from '../../mobile/FakeSearchInput';
import { ModalContent, ModalDialog } from '../../modals';

function getSuggestionValue(suggestion) {
  return (suggestion && suggestion.label) || '';
}

const theme = {
  container: 'location-container',
  input: `form-control location-input ${isSistaminuten() ? 'sistaminuten' : ''}`,
  suggestionsList: 'location-autocomplete',
  suggestionFirst: 'react-autosuggest__suggestion--first',
  suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
  suggestionsContainer: 'location-autocomplete-container',
  suggestionsContainerOpen: 'location-autocomplete-container--open',
};

const modalStyle = {
  overlay: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 10050,
    overflow: 'scroll',
    outline: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  content: {
    maxWidth: 400,
  },
};

class Location extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.inputContainerRef = createRef(null);
    this.inputRef = createRef(null);
    this.autosuggestRef = createRef(null);
    let { location } = url.getUrlParameters(props);
    if (!isServer && location === __('currentLocation') && getCookie('BokadirektLocationData')) {
      let params = getCookie('BokadirektLocationData');
      this.props.update(params);
      location = params.location;
    }
    this.state = {
      value: location || '',
      oldValue: location || '',
      suggestions: [
        {
          type: 'popular',
          title: 'popular',
          suggestions: [{ type: 'geolocation', label: __('currentLocation') }],
        },
      ],
      hitEnterOnce: false,
      geolocationRunning: false,
      showSuggestions: false,
      isModalOpen: false,
      storedSuggestions: [],
      selected: location ? true : false,
      hasHistory: false,
    };
    if (this.props.isMobileView && !isServer) {
      Modal.setAppElement('body');
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.initLocationSearchFromHistory();
  }

  componentDidUpdate(prevProps) {
    const current = url.getUrlParameters(this.props);
    const prev = url.getUrlParameters(prevProps);

    /**
     * If the location has changed, and the state does not match the url,
     * update the state to match the url and perform a search to keep
     * state and url in sync.
     * (this happens when the user navigates back and forth with the browser buttons)
     */
    if (prev.location !== current.location && this.state.value !== current.location) {
      this.setState({ value: current.location }, () => {
        this.props.update({ location: current.location, locationId: undefined });
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  openModal = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
    this.props.forceClose?.();
  };

  onChange = (event, { newValue, method }) => {
    if (this._isMounted) {
      if (event.target.id === 'remove_suggested') {
        return;
      }
      this.setState({
        oldValue: this.state.value,
        value: newValue || '',
        selected: false,
      });
    }
  };

  onFocus = (event) => {
    this.setState({ hitEnterOnce: false, showSuggestions: true });
  };

  onBlur = (event, { highlightedSuggestion }) => {
    if (this._isMounted) {
      // select My location as default
      if (highlightedSuggestion === null) {
        // if no value in location input
        if (this.state?.value?.length <= 0) {
          this.autoSelectMyLocation();
        }
      } else if (highlightedSuggestion.label !== this.state.value) {
        this.setState(
          {
            value: highlightedSuggestion.label,
            selected: false,
          },
          async () => {
            this.props.update(
              { location: highlightedSuggestion.label, locationId: highlightedSuggestion?.id || undefined },
              true,
            );
          },
        );
        // this.setActiveFirstSuggestion(false);
      }
    }
  };

  setActiveFirstSuggestion = (skip = true) => {
    if (this.autosuggestRef.current) {
      const section = this.state.hasHistory ? 1 : 0;
      if (this.state.suggestions[section]?.suggestions[1]) {
        const location = this.state.suggestions[section]?.suggestions[1];
        if (location.label !== this.state.value) {
          this.autosuggestRef.current.updateHighlightedSuggestion(section, 1);
          this.props.update(
            {
              location: location.label,
              locationId: location?.id || undefined,
              sort: 'popular',
              changedDefaultSorting: true,
              position: undefined,
              lat: undefined,
              lon: undefined,
            },
            skip,
          );
        }
      }
    }
  };

  autoSelectMyLocation = () => {
    trackMpEvent('suggested_item_clicked', {
      screen_name: 'search_autocomplete_location',
      suggestion_type: 'user_location',
    });

    this.setState({ showSuggestions: true, selected: true });
    this.closeModal();
    return this.geolocation();
  };

  renderSuggestion = (suggestion) => {
    const removeSuggestionFromCache = () => {
      const HISTORY = 'history';
      const historyIndex = this.state.storedSuggestions.findIndex((suggestion) => suggestion.type === HISTORY);
      const currentHistorySuggestions = this.state.storedSuggestions[historyIndex];
      const updatedHistorySuggestions = currentHistorySuggestions.suggestions.filter(
        (currentSuggestion) => currentSuggestion.label !== suggestion.label,
      );

      this.setState({
        suggestions: [
          ...(updatedHistorySuggestions.length
            ? [
                {
                  suggestions: updatedHistorySuggestions.slice(0, 3),
                  title: HISTORY,
                  type: HISTORY,
                },
              ]
            : []),
          ...this.state.suggestions.filter((suggestion) => suggestion.type !== HISTORY),
        ],
        storedSuggestions: [
          ...(updatedHistorySuggestions.length
            ? [
                {
                  suggestions: updatedHistorySuggestions,
                  title: HISTORY,
                  type: HISTORY,
                },
              ]
            : []),
          ...this.state.suggestions.filter((suggestion) => suggestion.type !== HISTORY),
        ],
      });
    };
    return (
      <div className="flex justify-between">
        <span className={suggestion.type}>{suggestion.label}</span>
        {suggestion.type === 'history' && (
          <button
            id="remove_suggested"
            onClick={(e) => {
              e.preventDefault();
              if (!isMobile()) {
                this.inputRef.current?.focus();
              }
              removeSuggestionFromCache();
              autocompleteService.deleteLocationSuggestion(suggestion.label);
            }}
            className="px-4">
            <div className="pointer-events-none block">
              <CloseIconThin className="text-black-700 h-3 w-3" />
            </div>
          </button>
        )}
      </div>
    );
  };

  onKeyDown = (event) => {
    if (event.keyCode === 13) {
      if (this.props.isMobileView) {
        event.preventDefault();
        this.props.update({ location: this.state.value });
        this.setState({ isModalOpen: false, selected: true });
      } else {
        if (!this.state.hitEnterOnce && !this.state.geolocationRunning) {
          // if enter
          event.preventDefault();
          this.setState({ hitEnterOnce: true });
        } else {
          this.props.update({ location: this.state.value });
        }
      }
    }
  };

  initLocationSearchFromHistory = async () => {
    const { value } = this.state;
    const { state: { fromHome } = { fromHome: false } } = this.props.location;
    const noDefaultLocation = sessionStorage.getItem('noDefaultLocation');

    if (!value && !fromHome && !noDefaultLocation) {
      const { data, error } = await promiseWrapper(autocompleteService.getLocationSuggestions(''));

      if (error) {
        console.error('Error while fetching location suggestions: ', error);
        return;
      }

      const [lastLocationSearch] = data?.history;
      if (lastLocationSearch) {
        this.onSuggestionSelected(null, {
          suggestion: lastLocationSearch,
          suggestionValue: (lastLocationSearch && lastLocationSearch.label) || '',
        });
      }
    }
  };

  onSuggestionsFetchRequested = async ({ value, reason }) => {
    if (this._isMounted) {
      if (reason !== 'input-focused' && reason !== 'input-changed') {
        return;
      }

      const { data, error } = await promiseWrapper(autocompleteService.getLocationSuggestions(value));

      if (error) {
        console.error('Error while fetching location suggestions: ', error);
        return;
      }

      const suggestions = data?.results || [];
      if (suggestions[0]) {
        suggestions[0].highlight = true;
      }

      const history = (data?.history || []).filter((item) => item && item.label);

      suggestions.unshift({ type: 'geolocation', label: __('currentLocation') });

      const suggestionsArray = [
        {
          type: 'popular',
          title: 'popular',
          suggestions: suggestions,
        },
      ];

      const storedSuggestions = [
        {
          type: 'popular',
          title: 'popular',
          suggestions: suggestions,
        },
      ];

      if (history && history.length) {
        this.setState({
          hasHistory: true,
        });

        suggestionsArray.unshift({
          type: 'history',
          title: 'history',
          suggestions: history.slice(0, 3),
        });

        storedSuggestions.unshift({
          type: 'history',
          title: 'history',
          suggestions: history,
        });
      }

      this.setState(
        {
          suggestions: suggestionsArray,
          storedSuggestions: storedSuggestions,
        },
        () => {
          this.setActiveFirstSuggestion();
        },
      );
    }
  };

  onSuggestionsClearRequested = () => {
    if (this._isMounted) {
      this.props.update({ location: this.state.value }, true);
      this.setState({ showSuggestions: false });
    }
  };

  onSuggestionSelected = (event, { suggestion, suggestionValue }) => {
    if (this._isMounted) {
      if (suggestion.type === 'geolocation') {
        trackMpEvent('suggested_item_clicked', {
          screen_name: 'search_autocomplete_location',
          suggestion_type: 'user_location',
        });

        this.setState({ showSuggestions: false });
        this.closeModal();
        return this.geolocation();
      }

      trackMpEvent('suggested_item_clicked', {
        screen_name: 'search_autocomplete_location',
        suggestion_type: suggestion.type,
      });

      if (event?.target?.id === 'remove_suggested') {
        return;
      }

      this.setState(
        {
          value: suggestionValue,
          isModalOpen: false,
          showSuggestions: false,
          selected: true,
        },
        async () => {
          const params = {
            location: suggestion.label,
            locationId: suggestion?.id || undefined,
            position:
              suggestion.position && suggestion.position.lat && suggestion.position.lon
                ? suggestion.position.lat + '-' + suggestion.position.lon
                : '',
          };
          await this.props.update(params);
        },
      );
    }

    this.props.forceClose?.();
  };

  clear = () => {
    if (this._isMounted) {
      sessionStorage.setItem('noDefaultLocation', true);
      this.setState({ value: '', selected: false }, () => {
        this.props.clear();
      });
    }
  };

  renderSectionTitle = (section) => {
    return section.title ? (
      <div className="text-black-600 text-m py-3 pl-3 font-semibold uppercase">{__(section.title)}</div>
    ) : null;
  };

  getSectionSuggestions = (section) => {
    return section.suggestions;
  };

  renderInputComponent = (inputProps) => {
    const height = this.inputContainerRef.current?.clientHeight || 0;
    const { key, ...rest } = inputProps;
    return (
      <>
        <div
          ref={this.inputContainerRef}
          className={
            this.props.isMobileView
              ? `scrolled:shadow fixed left-0 right-0 top-0 z-10 space-y-4 bg-white px-4 py-6 duration-100`
              : ''
          }>
          <div className="input-container relative">
            <button
              onClick={this.closeModal}
              className="pl-md py-sm pr-sm absolute left-0 top-0 h-full !w-auto outline-none"
              aria-label={__('SearchCityOrArea')}>
              <Icon variant="back" />
            </button>
            <input key={key} {...rest} />
            {inputProps.value && inputProps.value.length > 0 && (
              <button
                className="absolute right-0 top-0 z-10 h-full !w-auto px-4"
                onClick={this.clear}
                onMouseDown={this.clear}
                aria-label={__('clear')}>
                <Icon variant="close-circle" />
              </button>
            )}
          </div>
        </div>
        {this.props.isMobileView && <div style={{ height: height - 20 }} className="sticky left-0 right-0 top-0"></div>}
      </>
    );
  };

  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    const { key, ...rest } = containerProps;
    return (
      <div key={key} {...rest}>
        {children}
      </div>
    );
  };

  setLatitudeAndLongitude = (lat, lon) => {
    const params = {
      location: __('currentLocation'),
      locationId: undefined,
      position: lat && lon ? lat + '-' + lon : '',
      changedDefaultSorting: true,
    };

    this.setState(
      {
        geolocationRunning: false,
        isModalOpen: false,
        selected: true,
        value: __('currentLocation'),
      },
      () => {
        this.props.update(params);
      },
    );
  };

  geolocation() {
    getGeoLocation(
      this.setLatitudeAndLongitude,
      (cached) => {
        trackMpEvent('my_location_clicked', {
          screen_name: 'search_autocomplete',
        });
      },
      () => {
        this.setState({ geolocationRunning: true });
      },
      () => {
        this.setState({ geolocationRunning: false, isModalOpen: false });
      },
    );
  }

  renderMobile = () => {
    const { value, isModalOpen, suggestions } = this.state;
    const { source, search } = this.props;
    const inputProps = {
      placeholder: LOCATION_PLACEHOLDER,
      value: value || '',
      type: 'text',
      inputMode: 'search',
      onChange: this.onChange,
      onKeyDown: this.onKeyDown,
      autoFocus: true,
    };
    const clear = search?.location || (value && value !== LOCATION_PLACEHOLDER && value.length > 0);
    theme.container = !this.state.geolocationRunning ? 'location-container' : 'location-container loading';

    return (
      <div>
        {source !== 'home' && (
          <FakeSearchInput
            onClick={this.openModal}
            icon="location"
            onClear={this.clear}
            className="mb-3"
            content={search?.location || value || LOCATION_PLACEHOLDER}
            valid={clear}
          />
        )}
        <ModalDialog
          isOpen={isModalOpen || this.props.forceOpen}
          contentLabel="Modal"
          onRequestClose={this.closeModal}
          shouldCloseOnOverlayClick={false}
          closeTimeoutMS={1 /* otherwise the modal is not closed when suggestion is selected by pressing Enter */}
          className="modal-dialog modal-search modal-location"
          overlayClassName="modal-overlay"
          style={modalStyle}>
          <ModalContent size="fullscreen">
            <div className="relative px-4" onScroll={this.handleScroll}>
              <Autosuggest
                ref={this.autosuggestRef}
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionSelected={this.onSuggestionSelected}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                renderSuggestionsContainer={this.renderSuggestionsContainer}
                inputProps={inputProps}
                renderInputComponent={this.renderInputComponent}
                alwaysRenderSuggestions={true}
                theme={theme}
                id="location-autosuggest"
                getSectionSuggestions={this.getSectionSuggestions}
                renderSectionTitle={this.renderSectionTitle}
                multiSection
              />
            </div>
          </ModalContent>
        </ModalDialog>
      </div>
    );
  };

  shouldRenderSuggestions = () => {
    return true;
  };

  render() {
    if (this.props.isMobileView) return this.renderMobile();
    const { value, suggestions } = this.state;
    const { source, isUserLocationLoading } = this.props;
    const inputProps = {
      placeholder: __('Enter city or area'),
      value: value || '',
      type: 'search',
      onChange: this.onChange,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      onKeyDown: this.onKeyDown,
      ref: this.inputRef,
    };

    theme.container =
      !this.state.geolocationRunning && !isUserLocationLoading ? 'location-container' : 'location-container loading';
    theme.input =
      value && value.length > 0
        ? `form-control location-input source-${source} valid ${isSistaminuten() ? 'sistaminuten' : ''}`
        : `form-control location-input source-${source} ${isSistaminuten() ? 'sistaminuten' : ''}`;

    return (
      <Autosuggest
        ref={this.autosuggestRef}
        suggestions={suggestions}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        inputProps={inputProps}
        renderInputComponent={this.renderInputComponent}
        onSuggestionSelected={this.onSuggestionSelected}
        focusInputOnSuggestionClick={false}
        theme={theme}
        alwaysRenderSuggestions={this.state.showSuggestions}
        id="location-autosuggest"
        getSectionSuggestions={this.getSectionSuggestions}
        renderSectionTitle={this.renderSectionTitle}
        multiSection
      />
    );
  }
}

function mapStateToProps(state) {
  const { loading } = state.loading;
  const { search } = state;

  return {
    search,
    loading,
  };
}

const LocationAutosuggest = withMobileView(withRouter(connect(mapStateToProps)(Location)));
export default LocationAutosuggest;
