import { SEARCH_V2_FEATURE_FLAG } from '@/features/searchV2/constants';
import { useAppSelector } from '@/hooks';
import OldMobileSearch from './search';
import MobileSearch from './searchV2';

const MobileSearchPage = () => {
  const flags = useAppSelector((state) => state.flags);

  if (flags[SEARCH_V2_FEATURE_FLAG]?.value === 'on') {
    return <MobileSearch />;
  }

  return <OldMobileSearch />;
};

export default MobileSearchPage;
