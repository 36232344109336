import { CloseIconThin } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import { PlaceSubscriptionType } from '@/components/modules/place/Card';
import CardTags from '@/components/modules/place/CardTags';
import MatchedServiceItem from '@/components/modules/place/MatchedServiceItem';
import { Rating, StarRating } from '@/components/modules/rating';
import { SERVICES_IN_SERP_FEATURE_FLAG } from '@/constants/experimentConstants';
import { decodeUnicodeString, getImageSize } from '@/helpers';
import { useAppSelector } from '@/hooks';
import { useGetAmplitudeExperimentVariant } from '@/hooks/useAmplitudeExperiment';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { SearchPlace } from '@/types/api/services/search';
import { SyntheticEvent } from 'react';
import * as sanitizeHtml from 'sanitize-html';

type PlaceCardContentProps = {
  place: SearchPlace;
  showRatings?: boolean;
  showAddress?: boolean;
  address?: {
    street: string;
    city: string;
  };
  rating?: {
    score: number;
    count: number;
  };
  firstAvailableTime?: string;
  source: string;
  cardImage?: string;
  hasMainImage: boolean;
  getMainImage: () => JSX.Element;
  handleRemove?: (e: any) => void;
  removeAction: boolean;
  welcomeText?: string;
  subscriptionType: PlaceSubscriptionType;
  tooltipContainer?: React.RefObject<HTMLDivElement>;
};

const PlaceCardContent = (props: PlaceCardContentProps) => {
  switch (props.subscriptionType) {
    case 'essential':
      return <PlaceCardEssential {...props} />;
    case 'growth':
    case 'stable': {
      return props.hasMainImage ? <PlaceCardPremium {...props} /> : <PlaceCardEssential {...props} />;
    }
  }
};

const PlaceCardEssential = ({
  place,
  cardImage,
  showAddress,
  showRatings,
  address,
  firstAvailableTime,
  rating,
  source,
  handleRemove,
  removeAction,
  subscriptionType,
  tooltipContainer,
}: PlaceCardContentProps) => {
  const { isMobileView } = useMobileView();
  const iconClasses =
    'h-[60px] w-[60px] min-w-[60px] leading-[60px] sm:h-[90px] sm:w-[90px] sm:leading-[90px] sm:min-w-[90px]';

  return (
    <div className="w-full overflow-hidden">
      <div className="flex flex-col flex-wrap gap-3 sm:flex-row sm:items-center sm:justify-between sm:gap-6">
        <div className="flex flex-col">
          <div className="mb-3 flex">
            <div
              className={`border-black-100 bg-black-50 mr-4 flex items-center overflow-hidden rounded-lg border ${iconClasses}`}>
              {cardImage && <img width={90} height={90} src={cardImage} alt={place.about.name} loading="lazy" />}
              {!cardImage && (
                <span className={`text-black-900 text-center text-3xl ${iconClasses}`}>
                  {place.about.name && place.about.name.charAt(0)}
                </span>
              )}
            </div>
            <div className="flex flex-col justify-center gap-1 overflow-hidden">
              {isMobileView && (
                <h2 className="text-black-900 mt-0 w-full text-base font-semibold">{place.about.name}</h2>
              )}
              {!isMobileView && (
                <h2 className="text-black-900 text-h-m mb-2 mt-0 w-full font-semibold">{place.about.name}</h2>
              )}
              {isMobileView ? (
                <>
                  {showRatings && (
                    <div className="overflow-hidden text-ellipsis text-sm">
                      <StarRating count={rating.count} rating={rating.score} showValue />
                    </div>
                  )}
                  {showAddress && (
                    <div className="text-black-600 relative overflow-hidden overflow-ellipsis whitespace-nowrap text-sm">
                      <span>{address && address.street ? address.street.trim() : ''}</span>
                      {address && address.street && address.city ? ', ' : ''}
                      <span>{address && address.city ? address.city.trim() : ''}</span>
                    </div>
                  )}
                  {firstAvailableTime && subscriptionType !== 'essential' && (
                    <span className="text-primary text-sm">{firstAvailableTime}</span>
                  )}
                </>
              ) : (
                <>
                  {showAddress && (
                    <div className="flex flex-row items-center gap-1">
                      <img src="/images/location-icon.svg" width={12} height={12} alt="" />
                      <div className="text-black-900 relative overflow-hidden overflow-ellipsis whitespace-nowrap text-base">
                        <span>{address && address.street ? address.street.trim() : ''}</span>
                        {address && address.street && address.city ? ', ' : ''}
                        <span>{address && address.city ? address.city.trim() : ''}</span>
                      </div>
                    </div>
                  )}
                  {showRatings && (
                    <div className="flex h-6 items-center overflow-hidden text-ellipsis text-base">
                      <Rating
                        count={rating.count}
                        rating={rating.score}
                        justCount
                        showRatingText={false}
                        starSize="sm"
                      />
                    </div>
                  )}
                  {firstAvailableTime && subscriptionType !== 'essential' && (
                    <span className="text-primary flex items-center gap-1 text-base">
                      <img src="/images/time-icon-green.svg" width={12} height={12} alt="" />
                      {firstAvailableTime}
                    </span>
                  )}
                </>
              )}
              {removeAction && (
                <button
                  className="!outline-primary-500 absolute right-3 top-3 hidden h-8 w-8 overflow-hidden rounded-full p-2 duration-200 group-focus-within:block group-hover:block"
                  onClick={handleRemove}
                  aria-label={_s('removeFromFavorites')}>
                  <CloseIconThin className="relative z-10 h-full w-full text-white" />
                  <div className="bg-black-900 absolute inset-0 h-full w-full opacity-60"></div>
                </button>
              )}
            </div>
          </div>
          {isMobileView && (
            <div className="mt-auto">
              {<CardTags place={place} source={source} tooltipContainer={tooltipContainer} />}
            </div>
          )}
        </div>
        {!isMobileView && (
          <div>
            <CardTags place={place} source={source} tooltipContainer={tooltipContainer} />
          </div>
        )}
      </div>
    </div>
  );
};

const PlaceCardPremium = ({
  place,
  cardImage,
  getMainImage,
  hasMainImage,
  showAddress,
  showRatings,
  address,
  firstAvailableTime,
  rating,
  source,
  handleRemove,
  removeAction,
  welcomeText,
  subscriptionType,
  tooltipContainer,
}: PlaceCardContentProps) => {
  const { isMobileView } = useMobileView();
  const flags = useAppSelector((state) => state.flags);
  const getExperimentVariant = useGetAmplitudeExperimentVariant();

  const { images } = place || {};
  const hasTotalOfThreeImages = subscriptionType === 'growth' && hasMainImage && (images || []).length > 2;
  const second = hasTotalOfThreeImages && getImageSize(images[1], '250x', place.about.slug + '-2');
  const third = hasTotalOfThreeImages && getImageSize(images[2], '250x', place.about.slug + '-3');
  const iconClasses = 'h-[60px] w-[60px] leading-[60px] min-w-[60px]';

  const showMatchedServices =
    (flags[SERVICES_IN_SERP_FEATURE_FLAG]?.value ?? getExperimentVariant(SERVICES_IN_SERP_FEATURE_FLAG)?.value) ===
    'on';

  const matchedServices = showMatchedServices ? place?.matchedServices?.slice(0, 2) ?? [] : [];
  const matchedServiceHasFirstAvailableTime = matchedServices.some((service) => service.firstAvailableTime);

  const hideCampaignInTags = matchedServices.some((service) => service.finalPriceLabel);
  const hideWellnessInTags = matchedServices.some((service) => service.supportsWellnessCard);
  const hideTag = { campaign: hideCampaignInTags, wellness: hideWellnessInTags };
  const cardTagProps = { place, hideTag, source };

  const handleImageError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = `/images/bd-fallback-img${isMobileView ? '160x90' : '190x120'}.png`;
  };

  const sanitizedWelcomeText = sanitizeHtml(decodeUnicodeString(welcomeText), {
    allowedTags: [],
    allowedAttributes: {},
  });

  return (
    <div className="w-full overflow-hidden">
      <div className="flex flex-col gap-3 sm:grid sm:grid-cols-[minmax(240px,_388px)_minmax(260px,_1fr)] sm:gap-6">
        {hasTotalOfThreeImages ? (
          <div className="border-black-100 bg-black-50 flex h-max flex-col gap-1 overflow-hidden rounded-lg border">
            <div className="h-[194px] sm:h-[243px] sm:max-w-[388px]">{getMainImage()}</div>
            <div className="flex h-[90px] w-full gap-1 sm:h-[120px] sm:max-w-[388px]">
              <div className="w-full flex-1">
                <div className="h-full w-full">
                  <img
                    className="h-full w-full object-cover object-center"
                    alt=""
                    loading="lazy"
                    src={second}
                    onError={handleImageError}
                  />
                </div>
              </div>
              <div className="w-full flex-1">
                <div className="h-full w-full">
                  <img
                    className="h-full w-full object-cover object-center"
                    alt=""
                    loading="lazy"
                    src={third}
                    onError={handleImageError}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="border-black-100 bg-black-50 aspect-[16/10] items-center justify-center overflow-hidden rounded-lg border sm:aspect-auto sm:h-[243px]">
            {getMainImage()}
          </div>
        )}

        <div className="flex flex-col">
          {!isMobileView && (
            <h2 className="text-black-900 text-h-m mb-2 mt-0 line-clamp-2 inline-block overflow-hidden text-ellipsis font-semibold">
              {place.about.name}
            </h2>
          )}
          <div className="mb-3 flex sm:mb-6">
            <div
              className={`border-black-100 bg-black-50 mr-4 flex items-center overflow-hidden rounded-md border ${iconClasses}`}>
              {cardImage && (
                <img
                  width={60}
                  height={60}
                  src={cardImage}
                  alt={place.about.name}
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/images/bd-fallback-img60x60.png')}
                />
              )}
              {!cardImage && (
                <span className={`text-black-900 text-center text-3xl ${iconClasses}`}>
                  {place.about.name && place.about.name.charAt(0)}
                </span>
              )}
            </div>
            <div className="flex flex-col justify-center gap-1 overflow-hidden">
              {isMobileView && (
                <h2 className="text-black-900 mt-0 w-full text-base font-semibold">{place.about.name}</h2>
              )}
              {isMobileView ? (
                <>
                  {showRatings && (
                    <div className="overflow-hidden text-ellipsis text-sm">
                      <StarRating count={rating.count} rating={rating.score} justCount showValue />
                    </div>
                  )}
                  {showAddress && (
                    <div className="text-black-600 relative overflow-hidden overflow-ellipsis whitespace-nowrap text-sm">
                      <span>{address && address.street ? address.street.trim() : ''}</span>
                      {address && address.street && address.city ? ', ' : ''}
                      <span>{address && address.city ? address.city.trim() : ''}</span>
                    </div>
                  )}
                  {firstAvailableTime && !matchedServiceHasFirstAvailableTime && (
                    <span className="text-primary text-sm">{firstAvailableTime}</span>
                  )}
                </>
              ) : (
                <>
                  {showAddress && (
                    <div className="flex flex-row items-center gap-1">
                      <div className="text-black-900 relative overflow-hidden overflow-ellipsis whitespace-nowrap text-base">
                        <span>{address && address.street ? address.street.trim() : ''}</span>
                        {address && address.street && address.city ? ', ' : ''}
                        <span>{address && address.city ? address.city.trim() : ''}</span>
                      </div>
                    </div>
                  )}
                  {showRatings && (
                    <div className="flex h-6 items-center overflow-hidden text-ellipsis text-base">
                      <Rating count={rating.count} rating={rating.score} showRatingText={false} starSize="sm" />
                    </div>
                  )}
                  {firstAvailableTime && !matchedServiceHasFirstAvailableTime && (
                    <span className="text-success-700 flex items-center gap-1 text-base">
                      <Icon variant="clock" size="xs" color="success-700" />
                      {firstAvailableTime}
                    </span>
                  )}
                </>
              )}
              {removeAction && (
                <button
                  className="!outline-primary-500 absolute right-3 top-3 hidden h-8 w-8 overflow-hidden rounded-full p-2 duration-200 group-focus-within:block group-hover:block"
                  onClick={handleRemove}
                  aria-label={_s('removeFromFavorites')}>
                  <CloseIconThin className="relative z-10 h-full w-full text-white" />
                  <div className="bg-black-900 absolute inset-0 h-full w-full opacity-60"></div>
                </button>
              )}
            </div>
          </div>
          {sanitizedWelcomeText && subscriptionType === 'growth' && (
            <p className="text-black-600 mb-3 line-clamp-[3] max-w-[420px] overflow-hidden text-ellipsis text-sm sm:line-clamp-[5]">
              {sanitizedWelcomeText}
            </p>
          )}
          {/* Services */}
          {matchedServices.length > 0 && (
            <ul className="pb-md">
              {matchedServices.map((service, idx) => (
                <MatchedServiceItem
                  key={service.id}
                  service={service}
                  place={place}
                  underline={matchedServices.length - 1 !== idx}
                />
              ))}
            </ul>
          )}
          <div className="mt-auto">{<CardTags {...cardTagProps} tooltipContainer={tooltipContainer} />}</div>
        </div>
      </div>
    </div>
  );
};

export default PlaceCardContent;
