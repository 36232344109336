import { server } from '@/helpers';
import { useEffect, useState, useRef } from 'react';
import { Lightbox } from '@/components/modules/lightbox';
import Icon from '@/components/icons/Icon';
import Card from '@/components/elements/Card/Card';

const InstagramFeed = (props) => {
  const { profile, hasCard = true } = props;

  const [photos, setPhotos] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [visibleSlides, setVisibleSlides] = useState(1);
  const carouselRef = useRef(null);

  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  useEffect(() => {
    fetchInstagramPhotos(profile);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  async function fetchInstagramPhotos(profile) {
    try {
      const result = await server.request
        .get('/getInstagramFeed/' + profile)
        .then(server.handleSuccess)
        .catch(server.handleError);

      if (result && result.length > 0) {
        setPhotos(result);
      }
    } catch (error) {
      console.error('Failed to fetch Instagram photos:', error);
    }
  }

  const handleResize = () => {
    // const width = carouselRef.current?.offsetWidth || 0;
    const width = window.innerWidth;

    if (width >= 768) {
      setVisibleSlides(3); // Desktop md: Show 3
    } else if (width >= 640) {
      setVisibleSlides(2); // Tablet: Show 2
    } else {
      setVisibleSlides(1); // Mobile: Show 1
    }
    setCurrentIndex(0); // Reset index on resize to prevent incorrect offsets
    setLightboxIndex(0);
  };


  const lightboxPrevious = () => {
    setLightboxIndex((prevIndex) => (prevIndex - 1 + photos.length) % photos.length);
    gotoPrevious();
  };

  const lightboxNext = () => {
    setLightboxIndex((prevIndex) => {
      const maxIndex = photos.length - 1;
      return prevIndex < maxIndex ? prevIndex + 1 : prevIndex;
    });
    gotoNext();
  };


  const openLightbox = (index) => {
    setLightboxIndex(index);
    const maxIndex = photos.length - visibleSlides;
    setCurrentIndex(index < maxIndex ? index : maxIndex);
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
  };

  const gotoPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const gotoNext = () => {
    setCurrentIndex((prevIndex) => {
      const maxIndex = photos.length - visibleSlides;
      return prevIndex < maxIndex ? prevIndex + 1 : prevIndex;
    });
  };

  const fallbackLinkText = (
    <>
      <Icon variant="instagram" className="h-6 inline-block" alt="instagram" color='white' />
      <span className="ml-1">View on Instagram</span>
    </>
  );

  // do not render if no profile or no photos
  if (!profile || photos.length === 0) {
    return null;
  }

  const content = (
    <>
      <h2 className={`mb-3 flex w-full items-center gap-2 text-start ${hasCard ? 'px-lg' : ''}`}>
        {hasCard && <Icon variant="instagram" className="h-6" alt="instagram" />}
        <span className="text-black-900 text-md flex-1">Instagram</span>
        <span className="truncate">
          <a
            key="instagram"
            className="border-b"
            target="_blank"
            rel="noreferrer"
            href={`https://instagram.com/${profile}`}>
            @{profile}
          </a>
        </span>
      </h2>

      <div className={`relative overflow-hidden ${hasCard ? 'mx-lg py-md' : ''}`}>
        {/* Left Arrow */}
        {photos.length > visibleSlides && (
          <button
            onClick={gotoPrevious}
            className="absolute left-2 top-1/2 z-10 flex h-10 w-10 -translate-y-1/2 items-center justify-center rounded-full bg-white shadow-md focus:outline-none">
            <Icon variant="chevron-s-left" />
          </button>
        )}

        {/* Photo Carousel */}
        <div
          ref={carouselRef}
          className="flex gap-2 transition-transform duration-500 ease-in-out"
          style={{
            transform: `translateX(-${currentIndex * (100 / visibleSlides)}%)`
          }}>
          {photos.map((photo, index) => {
            const isLastVisibleSlide = index === currentIndex + visibleSlides - 1;
            return (
              <div
                key={photo.id}
                onClick={() => openLightbox(index)}
                className="flex flex-shrink-0 cursor-pointer items-center justify-center overflow-hidden rounded-lg bg-[#999] bg-cover bg-center bg-no-repeat focus:outline-none"
                style={{
                  width: isLastVisibleSlide
                    ? `calc(${100 / visibleSlides}%)` // Remove the gap on the last visible slide
                    : `calc(${100 / visibleSlides}% - 8px)`, // Subtract the gap for other slides
                }}>
                {photo.media_type === 'IMAGE' || photo.media_type === 'CAROUSEL_ALBUM' ? (
                  <img
                    loading="lazy"
                    src={photo.media_url}
                    alt={photo.caption || 'Instagram Image'}
                    className="h-64 w-full object-cover sm:h-48"
                  />
                ) : photo.media_type === 'VIDEO' ? (
                  <video poster={photo.thumbnail_url} className="h-64 w-full object-cover sm:h-48" onClick={(e) => e.preventDefault()}>
                    <source src={photo.media_url} />
                  </video>
                ) : null}
              </div>
            );
          })}
        </div>

        {/* Right Arrow */}
        {photos.length > visibleSlides && (
          <button
            onClick={gotoNext}
            className="absolute right-2 top-1/2 z-10 flex h-10 w-10 -translate-y-1/2 items-center justify-center rounded-full bg-white shadow-md focus:outline-none">
            <Icon variant="chevron-s-right" />
          </button>
        )}
      </div>

      {/* Lightbox for larger image view */}
      {isLightboxOpen && (
        <Lightbox
          images={photos.map((photo) => ({
            src: photo.media_url,
            caption: photo.caption,
            type: photo.media_type === 'VIDEO' ? 'video' : 'image',
            link: photo.permalink,
          }))}
          currentImage={lightboxIndex}
          onClose={closeLightbox}
          onClickNext={lightboxNext}
          onClickPrev={lightboxPrevious}
          isOpen={isLightboxOpen}
          backdropClosesModal={true}
          fallbackLinkText={fallbackLinkText}
        />
      )}
    </>
  );

  return hasCard ? <Card>{content}</Card> : content;
};

export default InstagramFeed;
