import { promiseWrapper } from '@/helpers';
import { _s } from '@/locale';
import { autocompleteService } from '@/services';
import { AutoSuggestionSection } from '../shared/types';
import { LocationSuggestion } from './LocationAutoSuggest.types';

export async function getSuggestionsFromSearchValue(value: string): Promise<{
  hasHistory: boolean;
  suggestions: AutoSuggestionSection<LocationSuggestion>[];
  storedSuggestions: AutoSuggestionSection<LocationSuggestion>[];
}> {
  const { data, error } = await promiseWrapper(autocompleteService.getLocationSuggestions(value));

  if (error) {
    console.error('Error while fetching location suggestions: ', error);
    return { suggestions: [], storedSuggestions: [], hasHistory: false };
  }

  const suggestions = data?.results || [];
  if (suggestions[0]) {
    suggestions[0].highlight = true;
  }

  const history = (data?.history || []).filter((item) => item && item.label);

  suggestions.unshift({ type: 'geolocation', label: _s('currentLocation') });

  const suggestionsArray = [
    {
      type: 'popular',
      title: 'popular',
      suggestions: suggestions,
    },
  ];

  const storedSuggestions = [
    {
      type: 'popular',
      title: 'popular',
      suggestions: suggestions,
    },
  ];

  const hasHistory = Boolean(history && history.length);

  if (hasHistory) {
    suggestionsArray.unshift({
      type: 'history',
      title: 'history',
      suggestions: history.slice(0, 3),
    });

    storedSuggestions.unshift({
      type: 'history',
      title: 'history',
      suggestions: history,
    });
  }

  return { suggestions: suggestionsArray, storedSuggestions, hasHistory };
}

export async function getLocationSuggestionFromSearchHistory(): Promise<LocationSuggestion> {
  const { data, error } = await promiseWrapper(autocompleteService.getLocationSuggestions(''));

  if (error) {
    console.error('Error while fetching location suggestions: ', error);
    return;
  }

  const [lastLocationSearch] = data?.history;
  return lastLocationSearch;
}
