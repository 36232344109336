import { H1 } from '@/components/elements/content';
import { LoadingIcon } from '@/components/icons';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import SEO from '@/components/modules/SEO';
import Breadcrumbs from '@/components/modules/pages/sidor/Breadcrumbs';
import { trackMpEvent, trackPage, url } from '@/helpers';
import { useLandingPage } from '@/hooks/useLandingPage';
import useMobileView from '@/hooks/useMobileView';
import useScrollToTop from '@/hooks/useScrollToTop';
import { getLandingPageComponents } from '@/services/landingPage';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import NotFound from '../404';

const LandingPage = ({ landingPage: ssrLandingPage = null }) => {
  const { slugID } = useParams();
  const { landingPage, error } = useLandingPage(ssrLandingPage, slugID);
  const { isMobileView } = useMobileView();

  useScrollToTop();

  useEffect(() => {
    const page = (slugID || '').replaceAll('-', '_');
    trackPage();
    trackMpEvent(`screen_view_${page}`, {});
  }, []);

  if (error) {
    return <NotFound />;
  }

  if (!landingPage) {
    return (
      <PageViewLayout type={isMobileView ? 'subView' : 'mainView'}>
        <LoadingIcon className="mx-auto h-8 w-8" />
      </PageViewLayout>
    );
  }

  const components = getLandingPageComponents(landingPage);
  const mainContent = components.main.map((c, i) => <div key={i}>{c}</div>);
  const sideBar = components.sidebar.map((c, i) => <div key={i}>{c}</div>);
  const seoTitle = landingPage.seo?.title || landingPage.title;
  const seoDescription = landingPage.seo?.description ?? '';

  return (
    <PageViewLayout
      type={isMobileView ? 'subView' : 'mainView'}
      {...(isMobileView && { back: true })}
      title={landingPage.title}
      wrapperClass="bg-gradient">
      <div className="pt-4">
        <div className="container-fixed mx-auto">
          <div className="justify-between gap-20 md:flex">
            <div className={`flex-1`}>
              <Breadcrumbs title={landingPage.title} />
              {!isMobileView && (
                <H1 styleAs="h3" className="!my-0 font-bold">
                  {landingPage.title}
                </H1>
              )}
              {mainContent}
            </div>
            <div className="w-full pb-[200px] md:max-w-xs md:pb-0 lg:max-w-sm">{sideBar}</div>
          </div>
        </div>
      </div>
      <SEO
        {...{ description: seoDescription, title: seoTitle }}
        url={`${url.getBaseUrl()}/fakta-och-rad/${slugID}`}></SEO>
    </PageViewLayout>
  );
};

// export default LandingPage;

const mapStateToProps = ({ landingPage }) => ({ landingPage });

export default connect(mapStateToProps)(LandingPage);
