import { BookAppointmentProvider } from '@/components/modules/modals/BookAppointment/BookAppointment.hooks';
import { SEARCH_V2_FEATURE_FLAG } from '@/features/searchV2/constants';
import SearchResultsPage from '@/features/searchV2/pages';
import { useAppSelector } from '@/hooks';
import { SearchProvider } from '@/hooks/search/useSearch';
import OldSearchResultsPage from './list';

const SearchPage = () => {
  const flags = useAppSelector((state) => state.flags);

  if (flags[SEARCH_V2_FEATURE_FLAG]?.value === 'on') {
    return (
      <SearchProvider>
        <BookAppointmentProvider>
          <SearchResultsPage />
        </BookAppointmentProvider>
      </SearchProvider>
    );
  }
  return <OldSearchResultsPage />;
};

export default SearchPage;
