import { SearchManagerOutput } from '@/hooks/search/useSearch';
import { _s } from '@/locale';

export function getSearchPlaceholderText(search: Pick<SearchManagerOutput, 'q' | 'location'>) {
  const { q, location } = search;
  if (q && location) {
    return q + ' ' + (location !== _s('currentLocation') ? location : _s('fakeSearchNear'));
  }
  if (q) {
    return q + ' ' + _s('allSweden');
  }
  if (location) {
    return _s('Everything') + ' ' + (location !== _s('currentLocation') ? location : _s('fakeSearchNear'));
  }
  return _s('Everything') + ' ' + _s('allSweden');
}
