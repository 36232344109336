import { Button } from '@/components/elements/forms/buttons';
import { LoadingIcon } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import FakeSearchInput from '@/components/modules/mobile/FakeSearchInput';
import { SearchManagerOutput } from '@/hooks/search/useSearch';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { autocompleteService } from '@/services';
import { useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import KeywordAutoSuggest from '../../components/AutoSuggest/KeywordAutoSuggest/KeywordAutoSuggest';
import { KeywordAutoSuggestManagerOutput } from '../../components/AutoSuggest/KeywordAutoSuggest/KeywordAutoSuggest.type';
import LocationAutosuggest from '../../components/AutoSuggest/LocationAutoSuggest/LocationAutoSuggest';
import {
  LocationAutoSuggestManagerOutput,
  LocationSuggestion,
} from '../../components/AutoSuggest/LocationAutoSuggest/LocationAutoSuggest.types';
import {
  AutoSuggestInputComponent,
  KeywordAutoSuggestSectionTitle,
  KeywordSuggestionItem,
  LocationAutoSuggestSectionTitle,
  LocationSuggestionItem,
} from '../../components/AutoSuggest/shared/templates';
import { AutoSuggestInputTheme } from '../../components/AutoSuggest/shared/types';
import DateTimePicker from '../../components/DateTimePicker/DateTimePicker';
import { DateTimePickerManagerOutput } from '../../components/DateTimePicker/DateTimePicker.types';
import { getSearchPlaceholderText } from './SearchForm.helpers';

type SearchFormProps = {
  searchManager: SearchManagerOutput;
} & {
  keywordAutoSuggestManager: KeywordAutoSuggestManagerOutput;
} & {
  locationAutoSuggestManager: LocationAutoSuggestManagerOutput;
} & {
  dateTimePickerManager: DateTimePickerManagerOutput;
};

const AUTOGUSGEST_THEME: AutoSuggestInputTheme = {
  container: 'relative w-full',
  suggestionsContainer: 'absolute z-[100] w-full right-0 left-0 max-h-[550px]',
  suggestion: 'cursor-pointer even:border-t even:border-b even:border-black-200 px-sm hover:bg-black-50',
  suggestionsContainerOpen:
    'z-[100] border border-black-200 bg-white pt-sm mt-xs rounded-md max-h-[550px] overflow-y-auto',
};

const SearchForm = (props: SearchFormProps) => {
  const keywordInputRef = useRef(null);
  const locationInputRef = useRef(null);
  const { searchManager, keywordAutoSuggestManager, locationAutoSuggestManager, dateTimePickerManager } = props;

  function handleOnClearKeyword() {
    keywordInputRef?.current?.focus?.();
    keywordAutoSuggestManager.onChange(null, { newValue: '' });
    searchManager.performSearch({ q: '', location: searchManager.location });
  }

  function handleClearLocation() {
    locationInputRef?.current?.focus?.();
    locationAutoSuggestManager.onChange({ target: { value: '' } });
    searchManager.performSearch({ q: searchManager.q, location: '' });
  }

  function handleOnSearchClick() {
    const { keyword } = keywordAutoSuggestManager;
    const { location } = locationAutoSuggestManager;
    const { startDate, endDate, timeOfDay } = dateTimePickerManager;
    const { lat, lon } = searchManager;

    searchManager.performSearch({
      q: keyword,
      location,
      startDate,
      endDate,
      timeOfDay,
      lat,
      lon,
    });
  }

  function handleRemoveLocationHistorySuggestion(suggestion: LocationSuggestion) {
    autocompleteService.deleteLocationSuggestion(suggestion.label);
    locationAutoSuggestManager.onClearHistorySuggestion(suggestion);
  }

  function handleOnAutoSuggestKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Enter' || event.keyCode === 13) {
      handleOnSearchClick();
    }
  }

  return (
    <div className="flex items-stretch">
      <div className="grid w-full grid-cols-3">
        <KeywordAutoSuggest
          inputRef={keywordInputRef}
          {...keywordAutoSuggestManager}
          onKeyDown={handleOnAutoSuggestKeyDown}
          theme={AUTOGUSGEST_THEME}
          renderInputComponent={({ key, ...inputProps }) => (
            <AutoSuggestInputComponent
              key={key}
              inputProps={inputProps}
              onClear={handleOnClearKeyword}
              leftSlot={<Icon variant="search" size="sm" />}
              className="rounded-bl-full rounded-tl-full"
            />
          )}
          renderSuggestion={(suggestion) => <KeywordSuggestionItem suggestion={suggestion} />}
          renderSectionTitle={(section) => <KeywordAutoSuggestSectionTitle {...section} />}
        />
        <LocationAutosuggest
          inputRef={locationInputRef}
          {...locationAutoSuggestManager}
          onKeyDown={(event) => handleOnAutoSuggestKeyDown(event)}
          theme={AUTOGUSGEST_THEME}
          renderInputComponent={({ key, ...inputProps }) => (
            <AutoSuggestInputComponent
              key={key}
              inputProps={inputProps}
              onClear={handleClearLocation}
              leftSlot={
                locationAutoSuggestManager.isFetchingUserPosition ? (
                  <LoadingIcon />
                ) : (
                  <Icon variant="location" size="sm" />
                )
              }
              className="border-l-0"
            />
          )}
          renderSuggestion={(suggestion) => (
            <LocationSuggestionItem
              suggestion={suggestion}
              onRemoveHistorySuggestion={handleRemoveLocationHistorySuggestion}
            />
          )}
          renderSectionTitle={(section) => <LocationAutoSuggestSectionTitle {...section} />}
        />
        <DateTimePicker
          {...dateTimePickerManager}
          renderInputComponent={(inputProps) => {
            const { hasFilterApplied, ...props } = inputProps;
            return (
              <div className="px-md border-black-900 relative flex items-center border border-l-0">
                <span className="pointer-events-none">
                  <Icon variant="calendar-events" />
                </span>
                <input {...props} />
                {hasFilterApplied && (
                  <button
                    onClick={dateTimePickerManager.onClearDateTime}
                    onMouseDown={dateTimePickerManager.onClearDateTime}
                    className="absolute right-0 top-0 z-10 h-full !w-auto px-4">
                    <Icon variant="close-circle" />
                  </button>
                )}
              </div>
            );
          }}
        />
      </div>
      <Button
        variant="primary"
        className="h-auto min-w-[96px] rounded-br-full rounded-tr-full"
        onClick={handleOnSearchClick}>
        {_s('search')}
      </Button>
    </div>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props: SearchFormProps) {
  const history = useHistory();
  const location = useLocation();
  const { isMobileView } = useMobileView();

  function handleOnFakeSearchInputClick() {
    history.push({
      pathname: '/search',
      search: location.search,
      state: { q: props.searchManager.q, location: props.searchManager.location },
    });
  }

  function handleOnClearFakeSearchInputClick() {
    history.push({
      pathname: '/search',
      search: location.search,
      state: { q: undefined, location: props.searchManager.location },
    });
  }

  if (isMobileView) {
    return (
      <FakeSearchInput
        isLoading={false}
        onClick={handleOnFakeSearchInputClick}
        valid={Boolean(props.searchManager.q || props.searchManager.location)}
        icon="search"
        onClear={handleOnClearFakeSearchInputClick}
        content={getSearchPlaceholderText(props.searchManager)}
        size="sm"
      />
    );
  }

  return <SearchForm {...props} />;
}
