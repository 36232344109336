import Chip from '@/components/elements/Chip';
import { SearchManagerOutput } from '@/hooks/search/useSearch';
import useMobileView from '@/hooks/useMobileView';
import { SortOption } from '@/types/state/search';
import Filter from '../components/Filter/Filter';
import { getSelectedFilters } from '../components/Filter/Filter.helpers';
import useFilterManager from '../components/Filter/Filter.hooks';
import { FilterOption } from '../components/Filter/Filter.types';
import Sorting from '../components/Sorting/Sorting';
import useSortingManager from '../components/Sorting/Sorting.hooks';

type SearchControlProps = SearchManagerOutput & {};

const SearchControls = (props: SearchControlProps) => {
  const { isMobileView } = useMobileView();
  const selectionOptions = getSelectedFilters(props.prefs);
  const filterManager = useFilterManager({
    initialState: { prefs: props.prefs, showOptions: false },
    urlPrefs: props.prefs,
    applyOnToggle: !isMobileView,
    onChangeAppliedFilters: handleOnChangeAppliedFilters,
  });

  const sortingManager = useSortingManager({
    initialState: { sort: props.sort, showOptions: false },
    urlSorting: props.sort,
    onChangeSorting: handleOnChangeSorting,
    onClearSorting: handleClearSorting,
  });

  function handleClearSorting() {
    props.performSearch({ sort: null });
  }

  function handleOnChangeSorting(sort: SortOption) {
    props.performSearch({ sort });
  }

  function handleOnChangeAppliedFilters(prefs: string) {
    props.performSearch({ prefs });
  }

  function handleOnRemoveSingleFilter(filterId: FilterOption['id']) {
    return (event) => {
      event?.stopPropagation();
      event?.preventDefault();
      const selectedOptions = getSelectedFilters(props.prefs);
      const newOptions = selectedOptions.filter((option) => option.id !== filterId).map((option) => option.id);
      props.performSearch({ prefs: newOptions.join(',') });
    };
  }

  return (
    <ul className="space-x-sm !relative z-10 flex">
      <li>
        <Sorting {...sortingManager} />
      </li>
      <li>
        <Filter {...filterManager} />
      </li>
      {!isMobileView && selectionOptions.length > 0 && (
        <li>
          <ul className="gap-sm no-scrollbar flex overflow-x-scroll rounded-sm">
            {selectionOptions.map((selectedOption) => (
              <li key={selectedOption.id} className="flex-shrink-0">
                <Chip label={selectedOption.label} onClose={handleOnRemoveSingleFilter(selectedOption.id)} />
              </li>
            ))}
          </ul>
        </li>
      )}
    </ul>
  );
};

export default SearchControls;
