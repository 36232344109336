import { _s } from '@/locale';
import { SEARCH_ACTION, SearchState } from '@/reducers/searchV2Reducer';
import { UrlSearchState } from '@/types/state/search';
import debounce from 'lodash.debounce';
import { createContext, useContext, useEffect, useRef } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '..';
import { DEFAULT_SEARCH_KEYWORD, DEFAULT_SEARCH_LOCATION } from './useSearch.constants';
import {
  buildServerRequestSearchParams,
  buildUrlSearchParams,
  getSearchUrlParameters,
  requestAndSetExtendedSearchResults,
  requestAndSetSearchResults,
  shouldSyncClientSearchQuery,
  trackSearchResultsPageView,
} from './useSearch.helpers';

type UseSearchManagerResult = ReturnType<typeof useSearchManager>;

export type SearchManagerOutput = ReturnType<typeof useSearchManager>;

const DEBOUNCE_SEARCH_REQUEST_MS = 500;

/**
 * Change the URL to sync url with search state
 */
function changeUrl({ what, where, searchQuery }: { what: string; where: string; searchQuery?: string }, history) {
  // This urls should be decoded here
  // fix only %
  what = (what || '').trim() || DEFAULT_SEARCH_KEYWORD;
  where = (where || '').trim() || DEFAULT_SEARCH_LOCATION;
  what = what.split('%25').join('%').split('%').join('%25');
  where = where.split('%25').join('%').split('%').join('%25');

  const pathname = `/${encodeURIComponent(what)}/${encodeURIComponent(where)}`;

  history.push({ pathname, search: searchQuery });
}

const useSearchManager = () => {
  const searchState = useAppSelector((state) => state.searchV2);
  const dispatcher = useAppDispatch();
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const urlState = getSearchUrlParameters({ match });

  // temporary until old search is removed to handle search made from landing page
  const oldSearch = useAppSelector((state) => state.search);

  const handleChangeUrl = (what = DEFAULT_SEARCH_KEYWORD, where = DEFAULT_SEARCH_LOCATION, searchQuery: string) =>
    changeUrl({ what, where, searchQuery }, history);

  /**
   * Perform search on mount if not the search results are already fetched
   * on SSR
   */
  useEffect(() => {
    if (!searchState.isSSRFetched) {
      /**
       * Temproary until old search is removed:
       * When user comes from landing page search form, use locationId
       * from old search state
       */
      const params = { ...urlState };
      if (location.state?.fromHome && oldSearch.location === urlState.location && oldSearch.locationId) {
        params.locationId = oldSearch.locationId;
      }

      performSearch(params);
    } else {
      // if search results are already fetched, send a page view event
      trackSearchResultsPageView(urlState, searchState);
    }
    return () => {
      dispatcher({ type: SEARCH_ACTION.SET_RESET_RESULTS });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * wait for all changes to be done before triggering search to avoid multiple requests
   */
  const debouncedSearch = useRef(
    debounce((params: UrlSearchState, state: SearchState, resetCache: boolean) => {
      requestAndSetSearchResults({ params, state, resetAllSearchItems: resetCache }, dispatcher);
    }, DEBOUNCE_SEARCH_REQUEST_MS),
  ).current;

  /**
   * Navigation initiated Search request (forces a new search)
   * and skips updating the url state (only used for navigation and not user initiated search)
   */
  function performSyncedSearch() {
    debouncedSearch(urlState, searchState, true);
  }

  function performSearch(
    params: UrlSearchState,
    options: { extendedSearch?: boolean; bounds?: string } = { extendedSearch: false, bounds: undefined },
  ) {
    const q = (() => {
      if ('q' in params) {
        return params.q;
      }
      return urlState.q;
    })();

    const location = (() => {
      if ('location' in params) {
        return params.location;
      }
      return urlState.location;
    })();

    const page = (() => {
      if ('page' in params) {
        return params.page;
      }

      return urlState.page;
    })();

    const updatedKeyword = q !== urlState.q;
    const updatedLocation = location !== urlState.location;
    const updatedPage = page !== urlState.page;
    const updatedSearchQuery = shouldSyncClientSearchQuery(params, urlState);

    /**
     * If keyword, location or search query is updated, but page param is not
     * then we need to reset the page to 0 and perform a new search (i.e not use cached results)
     */
    const resetSearch = (updatedKeyword || updatedLocation || updatedSearchQuery) && !updatedPage;

    if (resetSearch) {
      params['page'] = null;
    }

    /**
     * If location is updated and is not set to currentLocation
     * remove the lat and lon params
     */
    if (updatedLocation && location !== _s('currentLocation')) {
      params['lat'] = null;
      params['lon'] = null;
    }

    /**
     * If location is updated then use locationId from params
     * instead of the urlState
     */
    if (updatedLocation) {
      params['locationId'] = params['locationId'] || null;
    }

    /**
     * Check if search contains any search params, and if any new search params are added that needs sync
     * if key is included in params then it should override the urlState
     */
    const searchParams = buildServerRequestSearchParams(params, urlState, searchState);
    const urlSearchParams = buildUrlSearchParams(params, urlState);

    if (updatedKeyword || updatedLocation || updatedSearchQuery || resetSearch) {
      const searchQuery = urlSearchParams.toString() ?? window.location.search.replace('?', '');
      handleChangeUrl(q, location, searchQuery);
    }

    if (options.extendedSearch) {
      requestAndSetExtendedSearchResults({ params: urlState, state: searchState }, dispatcher);
      return;
    }

    if (options.bounds) {
      requestAndSetSearchResults(
        { params: urlState, state: searchState, resetAllSearchItems: resetSearch, bounds: options.bounds },
        dispatcher,
      );
      return;
    }

    const tryGetCachedResults = !resetSearch;

    if (tryGetCachedResults) {
      const currentPage = Math.max(0, Number(urlState.page) || 0);
      /**
       * if params.page is set, use that, otherwise use the current page
       * since the page is not being changed
       */
      const newPage = (() => {
        if ('page' in params) {
          return params.page || 0;
        } else {
          return currentPage;
        }
      })();

      const cachedResults = searchState.places?.[newPage] || [];

      if (cachedResults.length) return;
    }

    debouncedSearch({ ...searchParams, q, location }, searchState, resetSearch);
  }

  function paginate() {
    const { extendSearchIteration } = searchState;
    return {
      nextPage: () => {
        const currentPage = Math.max(0, Number(urlState.page) || 0);
        performSearch({ page: currentPage + 1 }, { extendedSearch: Boolean(extendSearchIteration) });
      },
      prevPage: () => {
        const currentPage = Math.max(0, Number(urlState.page) || 0);
        const page = currentPage > 1 ? currentPage - 1 : null;
        performSearch({ page }, { extendedSearch: Boolean(extendSearchIteration) });
      },
    };
  }

  return {
    q: urlState.q?.toLowerCase() !== DEFAULT_SEARCH_KEYWORD ? urlState.q : '',
    location:
      urlState.location?.toLowerCase() !== DEFAULT_SEARCH_LOCATION && urlState.location?.toLowerCase() !== 'var'
        ? urlState.location
        : '',
    startDate: urlState.startDate,
    endDate: urlState.endDate,
    timeOfDay: urlState.timeOfDay,
    prefs: urlState.prefs,
    sort: urlState.sort,
    lat: urlState.lat,
    lon: urlState.lon,
    page: urlState?.page || 0,
    places: searchState?.places || [],
    topSearch: searchState?.topSearch ?? { places: [] },
    results: searchState?.results || 0,
    fetching: searchState?.fetching || false,
    performSearch,
    performSyncedSearch,
    paginate,
  };
};

export const SearchContext = createContext<UseSearchManagerResult>({
  q: '',
  location: '',
  startDate: '',
  endDate: '',
  timeOfDay: undefined,
  places: [],
  topSearch: { places: [] },
  results: 0,
  fetching: false,
  page: 0,
  prefs: undefined,
  sort: undefined,
  lat: undefined,
  lon: undefined,
  performSearch: () => {},
  performSyncedSearch: () => {},
  paginate: () => ({ nextPage: () => {}, prevPage: () => {} }),
});

export const useSearchContext = (): UseSearchManagerResult => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error('useSearchContext must be used within a SearchProvider');
  }

  return context;
};

export const SearchProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const manager = useSearchManager();
  return <SearchContext.Provider value={manager}>{children}</SearchContext.Provider>;
};
