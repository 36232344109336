import { z } from 'zod';

const searchPlaceSubscriptionTypeSchema = z.union([z.literal('stable'), z.literal('essential'), z.literal('growth')]);

const searchPlaceSettingsSchema = z.object({
  displayReviews: z.boolean(),
  giftcard: z.boolean(),
  klarna: z.boolean(),
  campaigns: z.boolean().optional(),
  listView: z.boolean().optional(),
  qliro: z.boolean().optional(),
  subscriptionType: searchPlaceSubscriptionTypeSchema.optional(),
  wellness: z.boolean().optional(),
  hasMultiCompanies: z.boolean().optional(),
});

const searchPlaceAboutSettingsSchema = z.object({
  hasCampaigns: z.boolean().optional(),
  hasKlarna: z.boolean().optional(),
  hasQliro: z.boolean().optional(),
  displayCertificates: z.boolean().optional(),
  sellsGiftCard: z.boolean().optional(),
  wellness: z.boolean().optional(),
  topOfSearch: z.boolean().optional(),
});

const searchPlaceAboutSchema = z.object({
  name: z.string(),
  slug: z.string().optional(),
  settings: searchPlaceAboutSettingsSchema,
  sistaminutenDiscount: z.number().optional(),
});

const searchPlaceContactSchema = z.object({
  address: z
    .object({
      street: z.string().optional(),
      city: z.string().optional(),
      zipcode: z.string().optional(),
    })
    .optional(),
  position: z
    .object({
      lat: z.number().optional(),
      lon: z.number().optional(),
    })
    .optional(),
});

const searchPlaceMatchedServiceSchema = z.object({
  id: z.number().or(z.string()),
  name: z.string().optional(),
  description: z.string().optional(),
  employeeId: z.string().optional(),
  priceLabel: z.string().optional(),
  finalPriceLabel: z.string().optional(),
  supportsWellnessCard: z.boolean().optional(),
  durationLabel: z.string().optional(),
  availability: z.array(z.string()).optional(),
  firstAvailableTime: z.string().optional(),
  isBundle: z.boolean().optional(),
});

export type SearchPlaceMatchedService = z.infer<typeof searchPlaceMatchedServiceSchema>;

export const searchPlaceSchema = z.object({
  id: z.number(),
  name: z.string(),
  slug: z.string(),
  timezone: z.string(),
  settings: searchPlaceSettingsSchema,
  images: z.array(z.string()).optional(),
  topSearch: z.object({ bidId: z.number() }).optional(),
  distance: z.number().optional(),
  associations: z
    .array(
      z.object({
        imageUrl: z.string(),
        name: z.string().optional(),
        summary: z.string().optional(),
        slug: z.string().optional(),
      }),
    )
    .optional(),
  about: searchPlaceAboutSchema,
  contact: searchPlaceContactSchema,
  position: z.object({ lat: z.number(), lon: z.number() }).optional(),
  firstAvailableTime: z.string().optional(),
  matchedServices: z.array(searchPlaceMatchedServiceSchema).optional(),
  welcomeText: z.string().optional(),
  searchResultText: z.string().optional(),
  rating: z
    .object({
      score: z.number().optional(),
      count: z.number().optional(),
    })
    .optional(),
});

export type SearchPlace = z.infer<typeof searchPlaceSchema>;

const searchSchema = z.object({
  dynamicSearchExtension: z.boolean(),
  dunamicSearchExtensionOffset: z.number(),
  extendedSearchDisabled: z.boolean(),
  version: z.number(),
  places: z.array(searchPlaceSchema),
  topSearch: z.object({ places: z.array(searchPlaceSchema) }),
});

export type SearchSchema = z.infer<typeof searchSchema>;
