import Card from '@/components/elements/Card/Card';
import { DropdownButton } from '@/components/elements/forms/DropdownButton';
import Icon from '@/components/icons/Icon';
import { Dropdown } from '@/components/modules/dropdown';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { Fragment } from 'react/jsx-runtime';
import { getAppliedSorting, SORTING_OPTIONS } from './Sorting.helpers';
import { SortedBy, SortingManagerOutput } from './Sorting.types';

export type SortingProps = SortingManagerOutput & {};

const Sorting = (props: SortingProps) => {
  const { isMobileView } = useMobileView();
  const sortedBy = getAppliedSorting(props.urlSorting);

  // user has changed the default sorting
  const hasActiveSorting = props.urlSorting !== undefined;

  return (
    <DropdownButton
      toggleOpen={() => props.onShowOptions(true)}
      isActive={hasActiveSorting}
      close={props.onClearSorting}
      icon={<Icon variant="arrow-up-down" className="pointer-events-none" size="xs" />}
      isToggleOpen={props.showOptions && !isMobileView}
      newDesign
      className="!min-w-[109px] pl-2">
      <>
        <span className="text-m whitespace-nowrap">{_s(sortedBy.label)}</span>
        {props.showOptions && !isMobileView && (
          <Dropdown
            allowClick
            className="left-[-0.05rem] top-7 m-0 min-w-[20rem] rounded-b-2xl rounded-t-none p-0"
            onClickOutside={() => props.onShowOptions(false)}>
            <div className="px-sm pb-sm">
              <span className="text-capitalize text-xxs text-black-600 pb-md block">{_s('sortBy')}</span>
              <ul className="space-y-sm" role="radiogroup">
                <SortingOptions
                  sortedBy={sortedBy}
                  onChange={(sortOption) => props.onChangeSorting(sortOption.value)}
                />
              </ul>
            </div>
          </Dropdown>
        )}
        {props.showOptions && isMobileView && (
          <Fragment>
            <div className="bg-scrim-40 fixed inset-0 z-10"></div>
            <Card
              bottom
              closable={false}
              onClickOutside={() => props.onShowOptions(false)}
              className="fixed bottom-0 left-0 right-0 z-20 text-left">
              <span className="text-capitalize text-xxs text-black-600 pb-md block">{_s('sortBy')}</span>
              <ul className="space-y-sm" role="radiogroup">
                <SortingOptions
                  sortedBy={sortedBy}
                  onChange={(sortOption) => props.onChangeSorting(sortOption.value)}
                />
              </ul>
            </Card>
          </Fragment>
        )}
      </>
    </DropdownButton>
  );
};

const SortingOptions = ({ sortedBy, onChange }: { sortedBy: SortedBy; onChange: (sorting: SortedBy) => void }) => {
  return (
    <>
      {SORTING_OPTIONS.map((item, index) => {
        const isActive = item.value === sortedBy?.value;
        return (
          <li
            key={index}
            className="gap-sm flex cursor-pointer flex-row-reverse items-center"
            role="radio"
            aria-checked={isActive}
            onClick={() => onChange(item)}>
            <span className="flex-1">{_s(item.label)}</span>
            {isActive ? (
              <Icon variant="radio-checked" className="flex-shrink-0" />
            ) : (
              <Icon variant="radio-unchecked" className="flex-shrink-0" />
            )}
          </li>
        );
      })}
    </>
  );
};

export default Sorting;
