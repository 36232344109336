import Header from '@/components/elements/Header/Header';
import Label from '@/components/elements/Label/Label';
import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import Select from '@/components/elements/forms/Select/Select';
import Button, { ButtonProps } from '@/components/elements/forms/buttons/Button/Button';
import ListInput from '@/components/elements/forms/listInput/ListInput/ListInput';
import ListInputSelect from '@/components/elements/forms/listInput/ListInputSelect/ListInputSelect';
import Alert from '@/components/elements/notifications/Alert/Alert';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import Icon from '@/components/icons/Icon';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import CardWrapper from '@/components/modules/CardWrapper';
import SEO from '@/components/modules/SEO';
import UpdateUserInfo from '@/components/modules/UpdateUserInfo/UpdateUserInfo';
import useUpdateUserInfo from '@/components/modules/UpdateUserInfo/UpdateUserInfo.hooks';
import CheckoutTerms from '@/components/modules/checkout/CheckoutTerms/CheckoutTerms';
import DeliveryInfo from '@/components/modules/checkout/giftcard/DeliveryInfo/DeliveryInfo';
import DiscountCode from '@/components/modules/checkout/giftcard/DiscountCode';
import PersonalGreeting from '@/components/modules/checkout/giftcard/PersonalGreeting';
import SSNFormCard from '@/components/modules/checkout/giftcard/SSNForm';
import SelectAmount from '@/components/modules/checkout/giftcard/SelectAmount/SelectAmount';
import SelectType from '@/components/modules/checkout/giftcard/SelectType/SelectType';
import Modal from '@/components/modules/modals/redesign/Modal/Modal';
import ActionSteps from '@/components/templates/giftCards/ActionSteps';
import CheckoutDetails from '@/components/templates/giftCards/CheckoutDetails/CheckoutDetails';
import ProductInfo from '@/components/templates/giftCards/ProductInfo/ProductInfo';
import { SCREEN_NAME } from '@/constants/analytics';
import { GIFTCARD_DEFAULT_VALUE_SELECTED, WELLNESS_DEFAULT_VALUE_SELECTED } from '@/constants/giftcardConstants';
import { APP_ROUTES } from '@/constants/pages';
import { TermLinkIdentifier } from '@/constants/terms';
import { classnames, exportIdSlug, getWellnessCardExpiryDateFromToday, trackMpEvent, trackPage, url } from '@/helpers';
import { getCheckoutTerms } from '@/helpers/checkout';
import { arrayOfStrings } from '@/helpers/utilsTS';
import { useAppSelector } from '@/hooks';
import {
  GiftcardCheckoutFormDataProvider,
  GiftcardFormData,
  useGiftcardCheckoutFormData,
} from '@/hooks/useGiftcardCheckoutFormData';
import useMobileView from '@/hooks/useMobileView';
import { trackScreenView } from '@/hooks/useTrackScreenView';
import { _s } from '@/locale';
import { giftCardServices } from '@/services';
import { authorizeKlarnaPurchase, getInitializeKlarnaSDKOnce, loadKlarnaWidget } from '@/services/klarnaServices';
import { createPlaceGiftCardOrder } from '@/services/pgcPaymentServices';
import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import NotFound from '../404';
import { GiftcardCheckoutProvider, useGiftcardCheckout } from './GiftcardCheckout.hooks';
import useKlarnaGiftcardCheckoutManager from './klarna/KlarnaGiftcardCheckout.hooks';

const baseTranslationKey = 'pages.giftcard.checkout';

export type ProductType = 'placecard' | 'giftcard' | 'wellnesscard';

type CampaignType =
  | 'fathersDay'
  | 'christmas'
  | 'birthday'
  | 'giveAwayGift'
  | 'massage'
  | 'footcare'
  | 'acupuncture'
  | 'pt'
  | 'yoga';

function handleUpdateUserInfoCallback({ success, retry }, closeUpdateUserInfo: () => void) {
  trackScreenView({ name: success ? 'screen_view_update_profile_success' : 'screen_view_update_profile_fail' });

  if (success) closeUpdateUserInfo();

  toast(
    ({ closeToast }) => (
      <Snackbar
        label={_s(`${baseTranslationKey}.editProfileModal.snackbar.label.${success ? 'success' : 'error'}`)}
        type={success ? 'success' : 'danger'}
        action={
          <button onClick={success ? closeToast : retry}>
            {_s(`${baseTranslationKey}.editProfileModal.snackbar.action.${success ? 'success' : 'error'}`)}
          </button>
        }
      />
    ),
    { autoClose: 2000 },
  );
}

const getCampaignType = ({
  campaignType,
  productType,
}: {
  campaignType?: string;
  productType: 'wellnesscard' | 'giftcard' | 'placecard';
}): CampaignType | null => {
  if (productType === 'placecard') {
    return null;
  }

  if (productType === 'wellnesscard') {
    switch (campaignType) {
      case 'massage':
        return 'massage';
      case 'fotvard':
        return 'footcare';
      case 'akupunktur':
        return 'acupuncture';
      case 'personlig-traning':
        return 'pt';
      case 'yoga':
        return 'yoga';
      default:
        return null;
    }
  }

  switch (campaignType) {
    case 'fars-dag':
      return 'fathersDay';
    case 'julklapp':
      return 'christmas';
    case 'fodelsedag':
      return 'birthday';
    case 'ga-bort-present':
      return 'giveAwayGift';
    default:
      return null;
  }
};

const getProductInfo = (type: 'giftcard' | 'wellnesscard' | CampaignType | 'placecard', practitioner?: string) => {
  switch (type) {
    case 'giftcard':
      return {
        title: _s(`${baseTranslationKey}.productInfo.${type}.title`),
        subTitles: [_s(`${baseTranslationKey}.productInfo.${type}.subTitle`)],
        imgSrc: {
          desktop2xl: `/images/product-info-${type}-desktop-2xl.png`,
          desktop: `/images/product-info-${type}-desktop-lg.png`,
          mobile: `/images/product-info-${type}-mobile.png`,
        },
      };
    case 'wellnesscard':
      return {
        title: _s(`${baseTranslationKey}.productInfo.${type}.title`),
        subTitles: [
          _s(`${baseTranslationKey}.productInfo.${type}.subTitle`),
          _s(`${baseTranslationKey}.productInfo.${type}.subTitle2`),
        ],
        imgSrc: {
          desktop2xl: `/images/product-info-${type}-desktop-2xl.png`,
          desktop: `/images/product-info-${type}-desktop-lg.png`,
          mobile: `/images/product-info-${type}-mobile.png`,
        },
      };
    case 'fathersDay':
      return {
        title: _s(`${baseTranslationKey}.productInfo.campaigns.${type}.title`),
        subTitles: [
          _s(`${baseTranslationKey}.productInfo.campaigns.${type}.subTitle`),
          _s(`${baseTranslationKey}.productInfo.campaigns.${type}.subTitle2`),
        ],
        imgSrc: {
          desktop2xl: `/images/product-info-giftcard-${type}.png`,
          desktop: `/images/product-info-giftcard-${type}.png`,
          mobile: `/images/product-info-giftcard-${type}-mobile.png`,
        },
      };
    case 'christmas':
      return {
        title: _s(`${baseTranslationKey}.productInfo.campaigns.${type}.title`),
        subTitles: [_s(`${baseTranslationKey}.productInfo.campaigns.${type}.subTitle`)],
        imgSrc: {
          desktop2xl: `/images/product-info-giftcard-${type}.png`,
          desktop: `/images/product-info-giftcard-${type}.png`,
          mobile: `/images/product-info-giftcard-${type}-mobile.png`,
        },
      };
    case 'birthday':
    case 'giveAwayGift':
      return {
        title: _s(`${baseTranslationKey}.productInfo.campaigns.${type}.title`),
        subTitles: [_s(`${baseTranslationKey}.productInfo.campaigns.${type}.subTitle`)],
        imgSrc: {
          desktop2xl: `/images/product-info-giftcard-desktop-2xl.png`,
          desktop: `/images/product-info-giftcard-desktop-lg.png`,
          mobile: `/images/product-info-giftcard-mobile.png`,
        },
      };
    case 'massage':
    case 'footcare':
    case 'acupuncture':
    case 'pt':
    case 'yoga':
      return {
        title: _s(`${baseTranslationKey}.productInfo.campaigns.${type}.title`),
        subTitles: [_s(`${baseTranslationKey}.productInfo.campaigns.${type}.subTitle`)],
        imgSrc: {
          desktop2xl: `/images/product-info-wellnesscard-${type}.png`,
          desktop: `/images/product-info-wellnesscard-${type}.png`,
          mobile: `/images/product-info-wellnesscard-${type}-mobile.png`,
        },
      };
    case 'placecard':
      return {
        title: _s(`${baseTranslationKey}.productInfo.placecard.title`),
        subTitles: [_s(`${baseTranslationKey}.productInfo.placecard.subTitle`, { practitioner })],
        imgSrc: {
          desktop2xl: `/images/product-info-placecard-desktop-2xl.png`,
          desktop: `/images/product-info-placecard-desktop-lg.png`,
          mobile: `/images/product-info-placecard-mobile.png`,
        },
      };
    default:
      return {
        title: _s(`${baseTranslationKey}.productInfo.giftcard.title`),
        subTitles: [_s(`${baseTranslationKey}.productInfo.giftcard.subTitle`)],
        imgSrc: {
          desktop2xl: `/images/product-info-giftcard-desktop-2xl.png`,
          desktop: `/images/product-info-giftcard-desktop-lg.png`,
          mobile: `/images/product-info-giftcard-mobile.png`,
        },
      };
  }
};

const getCampaignAlerts = (type: CampaignType) => {
  if (type === 'birthday' || type === 'fathersDay' || type === 'giveAwayGift') {
    return;
  }

  if (type === 'christmas') {
    return (
      <Alert
        variant="information"
        leftSlot={<Icon variant="info-circle" />}
        body={_s(`${baseTranslationKey}.productInfo.campaigns.christmas.alert`)}
      />
    );
  }

  const typeTranslation = _s(`${baseTranslationKey}.productInfo.campaigns.${type}.type`);

  return (
    <Alert
      variant="information"
      leftSlot={<Icon variant="info-circle" />}
      body={
        <>
          <span>{_s(`${baseTranslationKey}.productInfo.campaigns.alert1`, { type: typeTranslation })}</span>{' '}
          <Link className="underline" to="/vad/var?wellness=true">
            {_s(`${baseTranslationKey}.productInfo.campaigns.link`)}
          </Link>{' '}
          <span>{_s(`${baseTranslationKey}.productInfo.campaigns.alert2`)}</span>
        </>
      }
    />
  );
};

const GiftcardCheckout = ({ productType }: { productType: ProductType }) => {
  const { isMobileView } = useMobileView();
  const user = useAppSelector((state) => state.users.user);
  const is404 = useAppSelector((state) => state.loading?.is404);
  const location = useLocation();
  const { campaign } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const { slugId } = useParams();
  const { id, slug } = exportIdSlug(slugId);
  const utm_source = queryParams.get('utm_source');
  const appUser = utm_source === 'mp_android_app' || utm_source === 'mp_ios_app';
  const { handleSubmit, formdata, reset, register } = useGiftcardCheckoutFormData();
  const updateUserInfo = useUpdateUserInfo({
    callback: (props) =>
      handleUpdateUserInfoCallback({ retry: props.retry, success: props.success }, handleCloseEditProfile),
    triggerSource: SCREEN_NAME.CHECKOUT_GIFTCARD_SUMMARY,
  });
  const [isDeliveryTooltipOpen, setIsDeliveryTooltipOpen] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const {
    summary,
    handleKlarnaPayment,
    klarna,
    employees,
    handlePlaceKlarnaPayment,
    loading,
    placeSellsGiftcard,
    placeName,
  } = useGiftcardCheckout();
  const deliveryType = formdata.type;
  const seoBaseString = productType === 'wellnesscard' ? 'buyGiftcardWellness' : 'buyGiftcardUniversal';
  const campaignType = getCampaignType({ campaignType: campaign, productType });
  const checkoutTerms = getCheckoutTerms('giftcard', TermLinkIdentifier.COUPON, _s('checkout.payWithKlarna'));
  const isPlaceGiftcard = productType === 'placecard';
  const typeTranslation =
    campaignType && productType === 'wellnesscard'
      ? _s(`${baseTranslationKey}.productInfo.campaigns.${campaignType}.type`)
      : '';

  const handleCloseEditProfile = () => {
    setShowEditProfile(false);
  };

  useEffect(() => {
    if (!isPlaceGiftcard) {
      handleKlarnaPayment();
    }

    trackPage();
    trackMpEvent('screen_shown', {
      screen_name: `${
        isPlaceGiftcard
          ? 'place_gift_card_landing_page'
          : productType === 'wellnesscard'
          ? 'wellness_card'
          : 'gift_card'
      }_landing_page`,
      place_id: id,
      campaign: campaignType ? campaign : undefined,
    });
  }, []);

  useEffect(() => {
    if (!loading && formdata.employee && employees?.length && isPlaceGiftcard) {
      handlePlaceKlarnaPayment();
    }
  }, [formdata.employee, employees, isPlaceGiftcard]);

  useEffect(() => {
    const newInitialData: GiftcardFormData = {
      amount:
        productType === 'giftcard' || isPlaceGiftcard
          ? GIFTCARD_DEFAULT_VALUE_SELECTED
          : WELLNESS_DEFAULT_VALUE_SELECTED,
      quantity: 1,
      type: 'digital',
    };
    reset(newInitialData);
  }, [productType, reset]);

  useEffect(() => {
    if (klarna?.clientToken) {
      const initializeKlarnaSDK = getInitializeKlarnaSDKOnce();
      initializeKlarnaSDK();
      loadKlarnaWidget('#klarna-payment', klarna.clientToken);
    }
  }, [klarna?.clientToken]);

  const bulletPoints = isPlaceGiftcard
    ? undefined
    : arrayOfStrings(_s(`${baseTranslationKey}.productInfo.${productType}.bulletPoints`));

  const deliveryInfoContent = deliveryType === 'digital' ? user?.contact?.email : user?.contact?.streetAddress;
  const deliveryInfoSubTitle =
    deliveryType === 'digital'
      ? user?.contact?.mobile
      : user?.contact?.postalCode && user?.contact?.locality
      ? `${user.contact.postalCode}, ${user.contact.locality}`
      : undefined;

  return isPlaceGiftcard && (is404 || (!placeSellsGiftcard && !loading)) ? (
    <NotFound />
  ) : (
    <PageViewLayout
      type={appUser ? 'subView' : 'mainView'}
      title={_s(`${baseTranslationKey}.page-title.${productType}`)}
      wrapperClass={isMobileView ? 'bg-brown-50' : 'bg-gradient'}>
      <form onSubmit={handleSubmit(() => {})}>
        <div className=" bg-brown-50 flex justify-center lg:bg-transparent">
          <div className="lg:py-xxl lg:container">
            <div className={`flex items-start lg:p-[30px] ${isMobileView ? '' : 'gap-[30px]'}`}>
              <div className={`flex grow-[9999] flex-col lg:max-w-[600px] ${isMobileView ? 'gap-md' : 'gap-lg'}`}>
                <ProductInfo
                  {...getProductInfo(campaignType ?? productType, isPlaceGiftcard ? slug : undefined)}
                  bulletPoints={bulletPoints}
                  {...(campaignType
                    ? {
                        bottomSlot: getCampaignAlerts(campaignType),
                      }
                    : {})}
                  middleSlot={
                    productType === 'wellnesscard' ? (
                      <Label
                        label={`${_s(`${baseTranslationKey}.productInfo.wellnesscard.label`, {
                          expire: getWellnessCardExpiryDateFromToday(),
                        })}`}
                        className="bg-success-900 text-white"></Label>
                    ) : undefined
                  }
                />

                {isPlaceGiftcard && employees?.length > 1 && !loading && (
                  <CardWrapper>
                    <div className="px-lg py-md gap-lg flex flex-col">
                      <Header label={'Välj utövare'} size="md" />
                      <ListInputSelect leftPadding={false} {...register('employee')}>
                        {employees.map((employee, index) => {
                          return (
                            <Select.Option key={index} value={employee.id}>
                              {employee.name}
                            </Select.Option>
                          );
                        })}
                      </ListInputSelect>
                    </div>
                  </CardWrapper>
                )}

                <SelectAmount title={_s(`${baseTranslationKey}.selectAmount.title`)} type={productType} />

                {productType === 'giftcard' && <SelectType onClick={() => setIsDeliveryTooltipOpen(true)} />}

                <DeliveryInfo
                  productType={productType}
                  onClick={() => setShowEditProfile(true)}
                  content={deliveryInfoContent}
                  subTitle={deliveryInfoSubTitle}
                />

                {deliveryType === 'physical' && productType === 'giftcard' && <PersonalGreeting />}

                {productType === 'giftcard' && <DiscountCode />}

                {productType === 'wellnesscard' && <SSNFormCard />}

                <div className="w-full">
                  <CardWrapper>
                    <div className="gap-xl p-lg flex flex-col">
                      <div id="klarna-payment"></div>
                      <SubmitCTA isPlaceGiftcard={isPlaceGiftcard} campaign={campaignType ? campaign : undefined} />
                      <CheckoutTerms
                        description={checkoutTerms.description}
                        linkLabel={checkoutTerms.linkLabel}
                        termsPageId={checkoutTerms.termsPageId}
                        onClose={() => {}}
                        triggerSource={SCREEN_NAME.CHECKOUT_GIFTCARD_SUMMARY}
                      />
                    </div>
                  </CardWrapper>
                </div>

                {isMobileView && productType === 'wellnesscard' && (
                  <ActionSteps
                    title={_s(`${baseTranslationKey}.actionSteps.title`)}
                    steps={[
                      { text: _s(`${baseTranslationKey}.actionSteps.stepOne`) },
                      {
                        text: _s(`${baseTranslationKey}.actionSteps.stepTwo`),
                        link: _s(`${baseTranslationKey}.actionSteps.bookLink`),
                      },
                      { text: _s(`${baseTranslationKey}.actionSteps.stepThree`) },
                    ]}
                  />
                )}
              </div>
              {!isMobileView && (
                <div
                  className={`sticky top-20 hidden lg:flex lg:grow-[100] lg:basis-[390px] lg:flex-col lg:gap-[30px]`}>
                  <CardWrapper className="space-y-lg w-[390px]">
                    <CheckoutDetails
                      selectedAmount={formdata.amount ?? formdata.customAmount}
                      selectedQuantity={formdata.quantity}
                      productType={productType}
                      deliveryType={deliveryType}
                      discount={
                        formdata.discountCode
                          ? { code: formdata.discountCode, amount: summary.discountAmount }
                          : undefined
                      }
                    />

                    {!isPlaceGiftcard && (
                      <Alert
                        variant="information"
                        leftSlot={<Icon variant="info-circle" />}
                        body={
                          productType === 'wellnesscard'
                            ? _s(`${baseTranslationKey}.alert.wellnesscard`, {
                                expire: getWellnessCardExpiryDateFromToday(),
                              })
                            : _s(`${baseTranslationKey}.alert.giftcard`)
                        }
                      />
                    )}
                  </CardWrapper>
                  {productType === 'wellnesscard' && (
                    <ActionSteps
                      insideCard={true}
                      title={_s(`${baseTranslationKey}.actionSteps.title`)}
                      steps={[
                        { text: _s(`${baseTranslationKey}.actionSteps.stepOne`) },
                        {
                          text: _s(`${baseTranslationKey}.actionSteps.stepTwo`),
                          link: _s(`${baseTranslationKey}.actionSteps.bookLink`),
                        },
                        { text: _s(`${baseTranslationKey}.actionSteps.stepThree`) },
                      ]}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </form>

      <Modal isOpen={isDeliveryTooltipOpen}>
        <Modal.Content floating={!isMobileView}>
          <Modal.Header
            title={_s(`pages.${productType}.checkout.deliveryTooltip.title`)}
            onClose={() => setIsDeliveryTooltipOpen(false)}
          />
          <div className="px-lg pb-lg">
            <p>{_s(`pages.${productType}.checkout.deliveryTooltip.body1`)}</p>
            <br />
            <p>{_s(`pages.${productType}.checkout.deliveryTooltip.body2`)}</p>
          </div>
        </Modal.Content>
      </Modal>

      <Modal isOpen={showEditProfile}>
        <Modal.Content floating={!isMobileView}>
          <Modal.Header title={_s(`${baseTranslationKey}.editProfileModal.title`)} onClose={handleCloseEditProfile} />
          <div className={classnames(isMobileView && 'px-lg')}>
            <div className="p-lg">
              <Header label={_s('Email')} size="lg" />
            </div>
            <ListInput id="email" disabled value={user?.contact?.email} />
            <UpdateUserInfo {...updateUserInfo} familyName givenName locality mobile postalCode streetAddress />
          </div>
        </Modal.Content>
      </Modal>

      <SEO
        {...(isPlaceGiftcard
          ? {
              title: _s(`seo.buyPlaceGiftCardTitle`, { name: placeName }),
              description: _s(`seo.buyPlaceGiftCardDescription`, { name: placeName }),
              url: `${url.getBaseUrl()}places/${slugId}/giftcard/checkout`,
            }
          : {
              description:
                campaignType && productType === 'wellnesscard'
                  ? _s(`${baseTranslationKey}.productInfo.campaigns.seo.wellness.description`, {
                      type: typeTranslation,
                    })
                  : _s(`seo.${seoBaseString}Description`),
              title:
                campaignType && productType === 'wellnesscard'
                  ? _s(`${baseTranslationKey}.productInfo.campaigns.seo.wellness.title`, { type: typeTranslation })
                  : _s(`seo.${seoBaseString}Title`),
              url: `${url.getBaseUrl()}${productType === 'giftcard' ? 'presentkort' : 'friskvardskort'}${
                campaignType ? '/' + campaign : ''
              }`,
            })}
      />
    </PageViewLayout>
  );
};

const SubmitCTA = ({ isPlaceGiftcard, campaign }: { isPlaceGiftcard: boolean; campaign: string }) => {
  const { handleKlarnaPayment, handlePlaceKlarnaPayment, submitting, klarna } = useGiftcardCheckout();
  const { klarnaGiftcardCheckoutAPI, status } = useKlarnaGiftcardCheckoutManager();
  const { handleSubmit } = useGiftcardCheckoutFormData();
  const payWithKlarna = async () => {
    const klarnaResponse = isPlaceGiftcard ? await handlePlaceKlarnaPayment() : await handleKlarnaPayment();
    if (klarnaResponse.sessionID) {
      sessionStorage.setItem(`${klarnaResponse.sessionID}_campaign`, campaign);
    }
    if (klarnaResponse) {
      authorizeKlarnaPurchase(klarnaResponse.orderLines, 'pay_later', (res) => {
        if (res.authorization_token) {
          klarnaGiftcardCheckoutAPI().onSubmitKlarna(
            res,
            klarnaResponse,
            isPlaceGiftcard ? createPlaceGiftCardOrder : giftCardServices.createGiftCardOrder,
          );
        }
      });
    }
  };

  const buttonProps: ButtonProps = {
    size: 'lg',
    block: true,
    label: _s(`checkout.payWithKlarna`),
    onClick: handleSubmit(payWithKlarna),
    className: 'whitespace-nowrap',
    leftIcon: <Icon variant={'payment-method-klarna-lg'} noFilter />,
    iconNoFilter: true,
    disabled: !klarna || status === 'SUBMITTING' || submitting,
  };

  return (
    <>
      {(status === 'SUBMITTING' || submitting) && <LoadingPlaceHolder className="fixed h-screen" />}
      <Button {...buttonProps} />
    </>
  );
};

const GiftcardCheckoutWithProviders = () => {
  const location = useLocation();
  const { slugId } = useParams();
  const isPlaceGiftcard = Boolean(slugId);
  const productType = isPlaceGiftcard
    ? 'placecard'
    : location.pathname.startsWith(APP_ROUTES.WELLNESSCARD_CHECKOUT)
    ? 'wellnesscard'
    : 'giftcard';

  return (
    <GiftcardCheckoutFormDataProvider
      initialData={{
        amount:
          productType === 'giftcard' || productType === 'placecard'
            ? GIFTCARD_DEFAULT_VALUE_SELECTED
            : WELLNESS_DEFAULT_VALUE_SELECTED,
        quantity: 1,
        type: 'digital',
      }}>
      <GiftcardCheckoutProvider>
        <GiftcardCheckout productType={productType}></GiftcardCheckout>
      </GiftcardCheckoutProvider>
    </GiftcardCheckoutFormDataProvider>
  );
};

export default GiftcardCheckoutWithProviders;
