import { showNavigationDrawer } from '@/actions';
import TabItem from '@/components/elements/tabs/TabItem/TabItem';
import { bottomNavBarNavigationItems } from '@/constants/bottomTabBarNavigation';
import { NAVIGATION_TRIGGER } from '@/constants/navigation';
import { Z_INDEX } from '@/constants/styleConstants';

import { classnames, isSistaminuten } from '@/helpers';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { DEALS_FEATURE_FLAG } from '@/pages/deals/Deals';
import { getScreenName, handleNavItemClicked } from '@/services/navigationServices';
import { NavItemIdentifier } from '@/types/navigation';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import AddPaymentMethodPromotion from '../../modals/AddPaymentMethodPromotion';
import NavigationDrawer from '../NavigationDrawer/NavigationDrawer';

const handleNavButtonClicked = (
  dispatch,
  identifier: NavItemIdentifier,
  navigationDrawerVisible: boolean,
  source: string,
  setShowAddPaymentMethodPromotion: Dispatch<SetStateAction<boolean>>,
) => {
  const handleClick = handleNavItemClicked(dispatch, getScreenName(source), NAVIGATION_TRIGGER.BOTTOM_NAVBAR);
  if (identifier === 'more') {
    dispatch(showNavigationDrawer({ show: !navigationDrawerVisible }));
    if (!isSistaminuten() && localStorage.getItem('closedPaymentMethodPromo') !== 'true') {
      setShowAddPaymentMethodPromotion(true);
    }
  } else {
    dispatch(showNavigationDrawer({ show: false }));
  }
  handleClick(identifier)();
};

const BOTTOM_NAV_BAR_HEIGHT = 80;

const BottomNavBar = ({ hideOnScroll = false, source }: { hideOnScroll?: boolean; source?: string }) => {
  const routeMatch = useRouteMatch();
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(true);
  const [showAddPaymentMethodPromotion, setShowAddPaymentMethodPromotion] = useState(false);
  const flags = useAppSelector((state) => state.flags);
  const dealsFeature = flags[DEALS_FEATURE_FLAG]?.value === 'on';

  const handleClosePaymentMethodPromotion = () => {
    localStorage.setItem('closedPaymentMethodPromo', 'true');
    setShowAddPaymentMethodPromotion(false);
  };

  const { show: navigationDrawerVisible = false } = useAppSelector((state) => state.navigationDrawer) as {
    show: boolean;
  };

  const user = useAppSelector((state) => state.users?.user);

  useEffect(() => {
    if (!hideOnScroll) {
      setShow(true);
      return;
    }

    // set show to false when scrolling down 200px, and true when scrolling up 10px from when its hidden
    let prevScrollPos = window.pageYOffset;
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingDown = prevScrollPos < currentScrollPos;
      const isScrollingUp = prevScrollPos > currentScrollPos;
      const isScrollingDownMoreThan200px = currentScrollPos > 200;
      const isScrollingUpMoreThan10px = currentScrollPos < prevScrollPos - 10;
      if (isScrollingDown && isScrollingDownMoreThan200px) {
        setShow(false);
      } else if (isScrollingUp && isScrollingUpMoreThan10px) {
        setShow(true);
      }
      prevScrollPos = currentScrollPos;
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hideOnScroll]);

  return (
    <>
      <div style={{ height: show ? BOTTOM_NAV_BAR_HEIGHT : 0 }}></div>
      <div
        id="bottom-nav-bar"
        className={classnames(
          'shadow-bottom-xs fixed bottom-0 left-0 right-0 w-full bg-white duration-300',
          show ? 'translate-y-0' : `translate-y-[80px]`,
        )}
        style={{ zIndex: Z_INDEX.BOTTOM_TAB_BAR }}>
        <div className="flex">
          {bottomNavBarNavigationItems
            .filter((item) => dealsFeature || item.id !== 'tab-nav-deals')
            .map((item) => {
              const isListPage = routeMatch.path === '/:q/:location' && item.to === '/vad/var';
              const isSelected = (item.to === routeMatch.path || isListPage) && !navigationDrawerVisible;
              const bookingsCount = user?.activeBookings?.length;

              return (
                <div key={item.identifier} className="pb-md flex flex-1 justify-center">
                  <TabItem
                    {...item}
                    selected={isSelected || (item.identifier === 'more' && navigationDrawerVisible)}
                    badge={item.identifier === NavItemIdentifier.Bookings && bookingsCount}
                    onClick={() =>
                      handleNavButtonClicked(
                        dispatch,
                        item.identifier,
                        navigationDrawerVisible,
                        source,
                        setShowAddPaymentMethodPromotion,
                      )
                    }
                  />
                </div>
              );
            })}
        </div>
      </div>
      <NavigationDrawer />
      <AddPaymentMethodPromotion
        key="add-payment-method-promotion"
        isOpen={showAddPaymentMethodPromotion}
        onClose={handleClosePaymentMethodPromotion}
      />
    </>
  );
};

export default BottomNavBar;
