import { searchActions } from '@/actions';
import { Button } from '@/components/elements/forms/buttons';
import { ArrowIcon, CalendarIcon } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import { Dropdown } from '@/components/modules/dropdown';
import { DATE_TIME_PLACEHOLDER } from '@/constants';
import { isServer, isSistaminuten, trackMpEvent, url } from '@/helpers';
import { themed, twBorderPrimary } from '@/helpers/theme';
import withMobileView from '@/hoc/withMobileView';
import { __ } from '@/locale';
import moment from 'moment';
import React from 'react';
import Calendar from 'react-calendar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FakeSearchInput from '../../mobile/FakeSearchInput';
import { ModalContent, ModalDialog } from '../../modals';
import ToggleSelect from './Select';

export const DATE_FORMAT = 'YYYY-MM-DD';

const TIME_OF_DAY_OPTIONS = [
  {
    key: 'morning',
    label: `${__('timeOfDay_morning')} ☀️`,
  },
  {
    key: 'afternoon',
    label: `${__('timeOfDay_afternoon')} 🌗`,
  },
  {
    key: 'evening',
    label: `${__('timeOfDay_evening')} 🌚`,
  },
];

const defaultDateTimeFilters = {
  timeOfDay: undefined,
  startDate: undefined,
  endDate: undefined,
};

export const getDateTimeFilterLabel = (
  startDate = moment().format(DATE_FORMAT),
  endDate = moment().add(3, 'weeks').format(DATE_FORMAT),
  timeOfDay,
) => {
  let dateLabel = __('anyDate');

  if (startDate && endDate) {
    const startDateObject = moment.utc(startDate);
    const endDateObject = moment.utc(endDate);
    const startDateMonthLabel = __(startDateObject.format('MMM').replace('.', ''));
    const endDateMonthLabel = __(endDateObject.format('MMM').replace('.', ''));
    const isSameMonth = startDateMonthLabel === endDateMonthLabel;
    const startDateLabel = `${startDateObject.format('D')}${isSameMonth ? '' : ` ${startDateMonthLabel}`}`;
    const endDateLabel = `${endDateObject.format('D')} ${endDateMonthLabel}, ${endDateObject.format('YYYY')}`;
    dateLabel = `${startDateLabel} - ${endDateLabel}`;
  }

  return `${dateLabel}${timeOfDay ? ` - ${__(`timeOfDay_${timeOfDay}`)}` : ''}`;
};

class DateTimeFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterOpen: false,
      filters: defaultDateTimeFilters,
    };
  }

  componentDidMount() {
    const { startDate, endDate, timeOfDay } = url.getUrlParameters(this.props);
    this.setState({
      filters: { startDate, endDate, timeOfDay },
    });
    window.addEventListener('popstate', this.handlePopState);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handlePopState);
  }

  handlePopState = (event) => {
    const { startDate, endDate, timeOfDay } = url.getUrlParameters(this.props);

    const filters = { startDate, endDate, timeOfDay };
    this.setState({ filters });
    this.props.dispatch(searchActions.setParameter({ ...filters, shouldUpdate: true }));
  };

  isActive() {
    const { startDate, endDate, timeOfDay } = this.props;
    return !!startDate || !endDate || !!timeOfDay;
  }

  applyFilter = () => {
    const { dispatch, history } = this.props;
    const { filters } = this.state;
    const newSearchQuery = url.addDateTimeFiltersWithValue(filters);
    history.push({ search: newSearchQuery });
    dispatch(searchActions.setParameter(filters));
    this.hideFilterOptions();
  };

  onChange = (key) => (option) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: filters[key] === option ? undefined : option,
      },
    });
  };

  onChangeDateRange = (startDate, endDate) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        startDate,
        endDate,
      },
    });
  };

  onChangeDateFromCalendar = (value) => {
    const [startDate, endDate] = value;
    const start = moment(startDate).format(DATE_FORMAT);
    const end = moment(endDate || startDate).format(DATE_FORMAT);
    this.onChangeDateRange(start, end);
  };

  removeFilter = (e) => {
    e.preventDefault();
    const { dispatch, history } = this.props;

    history.push({ search: url.clearDateTimeFilters() });
    dispatch(searchActions.removeParameter(['startDate', 'endDate', 'timeOfDay']));
    this.setState({ filters: defaultDateTimeFilters });
  };

  toggleFilterOptions = (e) => {
    const { filterOpen } = this.state;
    if (!filterOpen) {
      trackMpEvent('view_more_filters_clicked', {
        screen_name: this.props.trackPage,
      });
    }
    this.setState({ filterOpen: !filterOpen });
  };

  hideFilterOptions = (e) => {
    this.setState({ filterOpen: false });
    this.props.forceClose?.();
  };

  hasFilterApplied = () => Object.values(this.state.filters).some((f) => f);

  getOptionsHtml() {
    const { filters: { startDate, endDate, timeOfDay } = {} } = this.state;
    const dateRange = startDate && endDate ? [moment.utc(startDate), moment.utc(endDate)] : null;

    return (
      <span className={`date-time-filters ${this.isActive() ? 'active' : ''}`}>
        <Calendar
          onChange={this.onChangeDateFromCalendar}
          minDate={new Date()}
          selectRange={true}
          calendarType="ISO 8601"
          showNeighboringMonth={false}
          showWeekNumbers={true}
          prev2Label={null}
          nextLabel={<ArrowIcon className="mx-auto h-6 w-6 p-1" />}
          prevLabel={<ArrowIcon className="mx-auto h-6 w-6 rotate-180 p-1" />}
          next2Label={null}
          formatShortWeekday={(_, value) => ['Sö', 'Må', 'Ti', 'On', 'To', 'Fr', 'Lö'][value.getDay()]}
          locale="sv-SE"
          {...(dateRange ? { value: dateRange } : {})}
          allowPartialRange
        />
        <hr className="bg-black-200 mt-6 h-px w-full" />
        <div className="py-6">
          <ToggleSelect options={TIME_OF_DAY_OPTIONS} value={timeOfDay} onSelect={this.onChange('timeOfDay')} />
        </div>
        <div className="mx-auto max-w-[302px]">
          <Button size="lg" block onMouseDown={this.applyFilter}>
            {__('search')}
          </Button>
        </div>
      </span>
    );
  }

  getLabel() {
    const { startDate, endDate, timeOfDay } = this.props;

    if (this.isActive()) {
      return getDateTimeFilterLabel(startDate, endDate, timeOfDay);
    }
  }

  getFilterOptions() {
    if (isServer) return;
    const { filterOpen } = this.state;

    return (
      filterOpen && (
        <Dropdown className="date-time-dropdown" allowClick={filterOpen} onClickOutside={this.toggleFilterOptions}>
          <div className="py w-[480px] px-3 py-6">{this.getOptionsHtml()}</div>
        </Dropdown>
      )
    );
  }

  renderMobile = () => {
    return (
      <div>
        <FakeSearchInput
          onClick={() => {
            this.toggleFilterOptions();
          }}
          className="mb-3"
          valid={this.hasFilterApplied()}
          icon={'calendar-event'}
          onClear={this.removeFilter}
          content={this.getLabel() || DATE_TIME_PLACEHOLDER}
        />
        {!isServer && (
          <ModalDialog
            isOpen={this.state.filterOpen || this.props.forceOpen}
            contentLabel="Modal"
            onRequestClose={this.closeModal}
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={1 /* otherwise the modal is not closed when suggestion is selected by pressing Enter */}
            className="modal-search"
            appElement={document.getElementById('root')}>
            <ModalContent size="fullscreen">
              <div className="pointer-events-auto relative flex w-full flex-col bg-white bg-clip-padding outline-0">
                <div className="px-4 py-6">
                  <div
                    className={`relative flex h-11 items-center overflow-hidden rounded-full border bg-white ${
                      this.hasFilterApplied() ? twBorderPrimary() : 'border-black-600 '
                    }`}>
                    <button
                      onClick={this.hideFilterOptions}
                      onMouseDown={this.hideFilterOptions}
                      className="absolute bottom-0 left-0 top-0 flex w-11 items-center justify-center">
                      <Icon variant="back" />
                    </button>
                    <input
                      value={this.getLabel() || DATE_TIME_PLACEHOLDER}
                      className={`flex h-full w-full items-center pl-11 ${
                        this.hasFilterApplied() ? 'text-black-900' : 'text-black-600'
                      }`}
                      readOnly
                    />
                    {this.hasFilterApplied() && (
                      <button
                        onClick={this.removeFilter}
                        onMouseDown={this.removeFilter}
                        className="absolute bottom-0 right-2 top-0 flex w-8 items-center justify-center">
                        <Icon variant="close-circle" />
                      </button>
                    )}
                  </div>
                </div>
                <div className="relative top-0 mt-4 flex-1 basis-auto px-4">{this.getOptionsHtml()}</div>
              </div>
            </ModalContent>
          </ModalDialog>
        )}
      </div>
    );
  };

  render() {
    if (this.props.isMobileView) return this.renderMobile();

    return (
      <div className="date-time-container">
        <div className="pointer-events-none absolute left-0 top-[15px] z-10 flex w-10 items-center justify-center">
          <CalendarIcon className="h-4 w-4" />
        </div>
        <input
          className={`form-control date-time-input !w-full rounded-none pl-10 ${
            isSistaminuten() ? 'sistaminuten' : ''
          } ${
            this.hasFilterApplied()
              ? `${themed('border-primary', 'border-sm_primary')} text-black-900 pr-8`
              : `${themed('border-black-600', 'border-sm_primary')} text-black-600`
          }`}
          placeholder={__('when')}
          value={this.getLabel()}
          onChange={(e) => e.preventDefault()}
          onClick={this.toggleFilterOptions}
          readOnly
        />
        {this.hasFilterApplied() && (
          <button
            onClick={this.removeFilter}
            onMouseDown={this.removeFilter}
            className="absolute right-0 top-0 z-10 h-full !w-auto px-4">
            <Icon variant="close-circle" />
          </button>
        )}
        {this.getFilterOptions()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { search } = state;
  const { startDate, endDate, timeOfDay } = search;

  return {
    startDate,
    endDate,
    timeOfDay,
    search,
  };
}

const connectedDateTimeFilter = withMobileView(withRouter(connect(mapStateToProps)(DateTimeFilter)));
export default connectedDateTimeFilter;
